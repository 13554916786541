import { DateTimeDetailValue } from "../../../../components/shared/details-cells/DateTimeDetailValue";
import DetailsCells from "../../../../components/shared/details-cells/DetailsCells";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
// import RunStatusLabel from "../../common-for-log-sheet-and-run-logs/RunStatusLabel";
import { valueOrEmpty } from "../../../../utils/helpers/text";
import ActionLogLinkDetailView from "../../ActionLogLinkDetailView";
import RunStatusLabel from "../../common-for-log-sheet-and-run-logs/RunStatusLabel";
import {
  ApproverUserNameCluster,
  Flex,
  RunLogWrapper,
  CustomDetailsCellStyled,
  MediaTypeCell,
  GxpReadyCell,
  SystemStatusCell,
  GxpReadyAllCell,
  SystemStatusAllCell,
  ActionTypeCell
} from "../../common-for-log-sheet-and-run-logs/table-common-meta-info";
import ValueOrFallbackText from "../../common-for-log-sheet-and-run-logs/ValueOrFallbackText";
import { changeDateFormat } from "../../helpers";
import RUN_LOGS_DATA_MODEL from "./../../../../utils/constants/runLogsDataModel";
import { customItem } from "../../log-sheet/log-sheet-table/customItem";
import { DESCRIPTION_FIELD } from "../../../../utils/constants/descriptionField";

export const AllLogClusterTableMeta = {
  expandable: true,
  ExpandedComponent: ({ item, infoMeta }) => (
    <Flex>
      <DetailsCells Wrapper={RunLogWrapper} item={item} infoMeta={infoMeta} CellWrapper={CustomDetailsCellStyled} />
    </Flex>
  ),
  expandedComponentProps: {
    infoMeta: {
      fields: [
        {
          label: "Run start",
          component: ({ item }) => (
            <DateTimeDetailValue date={changeDateFormat(item?.runStartDate)} time={item.runStartTime} />
          )
        },
        {
          label: RUN_LOGS_DATA_MODEL.runEndDate.value,
          component: ({ item }) => (
            <DateTimeDetailValue date={changeDateFormat(item?.runEndDate)} time={item.runEndTime} />
          )
        },
        {
          label: RUN_LOGS_DATA_MODEL.eLNid.value,
          field: ["eLNid"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.runStatus.value,
          component: RunStatusLabel
        },
        {
          label: RUN_LOGS_DATA_MODEL.runIdentification.value,
          field: ["runIdentification"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.gxpReady.value,
          component: GxpReadyAllCell
        },
        {
          label: RUN_LOGS_DATA_MODEL.systemStatus.value,
          component: SystemStatusAllCell
        },
        {
          label: COMMON_LOGS_DATA_MODEL.approverUserName.value,
          field: ["approverUserName"]
        },
        {
          label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
          field: ["operatorUserName"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.samplesProcessed.value,
          field: ["samplesProcessed"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.numberOfRuns.value,
          field: ["numberOfRuns"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.defectId.value,
          field: ["defectId"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.mediaType.value,
          component: MediaTypeCell
        },
        {
          label: RUN_LOGS_DATA_MODEL.tipsUsed.value,
          component: ({ item }) => (
            <ValueOrFallbackText reduxPath={["runLogsForm", "tips"]} item={item.tipsUsed} defaultValue="-" />
          )
        },
        {
          label: RUN_LOGS_DATA_MODEL.assay.value,
          component: ({ item }) => <>{item.assay && item.assay.length > 0 ? item.assay.join(", ") : "-"}</>,
          props: {
            expand: true
          }
        },
        {
          label: COMMON_LOGS_DATA_MODEL.description.value,
          field: ["description"],
          props: {
            expand: true
          }
        }
      ]
    },
    actionInfoMeta: {
      fields: [
        {
          label: ACTION_LOGS_DATA_MODEL.actionDate.value,
          component: ({ item }) => changeDateFormat(item?.actionDate)
        },
        {
          label: ACTION_LOGS_DATA_MODEL.actionTime.value,
          component: ({ item }) => <DateTimeDetailValue time={item?.actionTime} />
        },
        {
          label: ACTION_LOGS_DATA_MODEL.actionType.value,
          component: ActionTypeCell
        },
        {
          label: ACTION_LOGS_DATA_MODEL.gxpReady.value,
          component: GxpReadyAllCell
        },
        {
          label: ACTION_LOGS_DATA_MODEL.systemStatus.value,
          component: SystemStatusAllCell
        },
        {
          label: ACTION_LOGS_DATA_MODEL.ecrNumber.value,
          field: ["ecrNumber"]
        },
        {
          label: COMMON_LOGS_DATA_MODEL.approverUserName.value,
          field: ["approverUserName"]
        },
        {
          label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
          field: ["operatorUserName"]
        },
        customItem({ dataModel: ACTION_LOGS_DATA_MODEL.softwareVerison, valueKey: "newValue", whiteSpace: false }),
        DESCRIPTION_FIELD,
        customItem({
          dataModel: ACTION_LOGS_DATA_MODEL.configurationDetails,
          valueKey: "newValue",
          whiteSpace: true,
          width: "100%"
        }),
        {
          field: ["externalDocument"],
          props: {
            style: { width: "100%" }
          },
          component: ({ item }) => {
            return <ActionLogLinkDetailView externalDocuments={item?.externalDocument} />;
          }
        }
      ]
    }
  },
  fields: {
    detailExpander: {
      text: ""
    },
    allLogDate: {
      sortable: true,
      text: "Date",
      component: ({ item }) => changeDateFormat(item?.allLogDate),
      width: 500,
      maxWidth: 500
    },
    logType: {
      text: "Type"
    },
    typeSpecificDetails: {
      text: "Type specific details (Action type/Run status)",
      component: ({ item }) => valueOrEmpty(item?.typeSpecificDetails, false, "-")
    },
    gxpReady: {
      text: RUN_LOGS_DATA_MODEL.gxpReady.value,
      component: GxpReadyCell
    },
    systemStatus: {
      text: RUN_LOGS_DATA_MODEL.systemStatus.value,
      component: SystemStatusCell
    },
    description: {
      text: RUN_LOGS_DATA_MODEL.description.value
    },
    ...ApproverUserNameCluster,
    equipmentModel: {
      text: COMMON_LOGS_DATA_MODEL.equipmentModel.value
    },
    equipmentNickName: {
      text: COMMON_LOGS_DATA_MODEL.equipmentNickName.value
    },
    ecrNumber: {
      text: ACTION_LOGS_DATA_MODEL.ecrNumber.value
    },
    eLNid: {
      text: RUN_LOGS_DATA_MODEL.eLNid.value,
      component: ({ item }) => valueOrEmpty(item?.eLNid, false, "-")
    },
    runIdentification: {
      text: RUN_LOGS_DATA_MODEL.runIdentification.value
    },
    samplesProcessed: {
      text: RUN_LOGS_DATA_MODEL.samplesProcessed.value
    },
    numberOfRuns: {
      text: RUN_LOGS_DATA_MODEL.numberOfRuns.value
    },
    defectId: {
      text: RUN_LOGS_DATA_MODEL.defectId.value
    },
    mediaType: {
      text: RUN_LOGS_DATA_MODEL.mediaType.value,
      component: MediaTypeCell
    },
    tipsUsed: {
      text: RUN_LOGS_DATA_MODEL.tipsUsed.value,
      component: ({ item }) => (
        <ValueOrFallbackText reduxPath={["runLogsForm", "tips"]} item={item.tipsUsed} defaultValue="-" />
      )
    },
    operatorUserName: {
      text: COMMON_LOGS_DATA_MODEL.operatorUserName.value
    },
    assay: {
      text: RUN_LOGS_DATA_MODEL.assay.value,
      component: ({ item }) => <span>{item.assay && item.assay.length > 0 ? item.assay.join(", ") : "-"}</span>
    }
  }
};

export const allLogTabletSizes = {
  runStartDate: "0 1 15%",
  runEndDate: "0 1 13%",
  subEquipment: "0 1 27%",
  eLNid: "0 1 13%",
  runStatus: "0 1 15%",
  runIdentification: "0 1 13%",
  equipmentModel: "0 1 16%",
  equipmentNickName: "0 1 13%",
  description: "0 1 25%",
  approverUserName: "0 1 12%",
  actionButtons: "0 0 132px"
};

export const allLogRegularSizes = {
  runStartDate: "0 1 15%",
  runEndDate: "0 1 13%",
  subEquipment: "0 1 27%",
  eLNid: "0 1 13%",
  runStatus: "0 1 15%",
  runIdentification: "0 1 13%",
  equipmentModel: "0 1 16%",
  equipmentNickName: "0 1 13%",

  description: "0 1 25%",
  approverUserName: "0 1 12%",
  actionButtons: "0 0 132px"
};

export default AllLogClusterTableMeta;
