import { combineReducers } from "redux";
import equipmentModelsReducer from "../features/log-book/redux/reducer";
import RunLogsFormReducer from "../features/log-book/log-book-item-form-dialog/redux/reducer";
import userReducer from "../features/user/redux/reducer";
import appSyncReducer from "./appSyncReducer";

const rootReducer = combineReducers({
  user: userReducer,
  equipmentModels: equipmentModelsReducer,
  runLogsForm: RunLogsFormReducer,
  appSyncClient: appSyncReducer
});

export default rootReducer;

export interface RootState {
  user: ReturnType<typeof userReducer>; // Assuming userReducer is properly typed
  equipmentModels: ReturnType<typeof equipmentModelsReducer>; // Assuming it returns a typed state
  runLogsForm: ReturnType<typeof RunLogsFormReducer>;
  appSyncClient: ReturnType<typeof appSyncReducer>;
}
