import React, { useState, useCallback, useEffect } from "react";
import { LogBookSelectContext } from "./context";
import { entryType } from "../../../../constants";
import {
  DigitalLabLogbookInstrumentModelType,
  InstrumentWithSubEquipmentDetailsModelType
} from "../../../../models/DigitalLabLogbookInstrumentModelType";

export const compareInstrument = <T extends { inventoryId: any }>(x: T, y: T) => x.inventoryId === y.inventoryId;

export const LogbookSelectedWrapper = ({
  children,
  selectedInstruments = [],
  equipmentDetail
}: {
  children: React.ReactNode;
  selectedInstruments: DigitalLabLogbookInstrumentModelType[];
  equipmentDetail: InstrumentWithSubEquipmentDetailsModelType;
}) => {
  const [filteredData, setFilteredData] = useState<DigitalLabLogbookInstrumentModelType[]>([]);

  useEffect(() => {
    if (!equipmentDetail?.subEquipment?.length) return;

    const subEquipmentData = equipmentDetail.subEquipment.flatMap((details) => {
      if (details?.entryType === entryType?.cluster && details?.subEquipment?.length) {
        return [details, ...details.subEquipment];
      }
      return [details];
    });

    setFilteredData(subEquipmentData);
  }, [equipmentDetail]);

  const [selected, setSelected] = useState(selectedInstruments);
  const [selectedOnlyParents, setSelectedOnlyParents] = useState<InstrumentWithSubEquipmentDetailsModelType[]>([]);
  const [isAllSelected, setAllSelected] = useState(false);
  const [selectedCount, setSelectedCount] = useState("");

  const onSelect = useCallback((item: InstrumentWithSubEquipmentDetailsModelType) => {
    setSelected((prev) => {
      return prev.some((i) => compareInstrument(i, item))
        ? prev.filter((i) => !compareInstrument(i, item))
        : [...prev, item];
    });
  }, []);

  const onAllSelect = useCallback(() => {
    setSelected((prev) =>
      filteredData.every((filteredItem) => prev.some((item) => compareInstrument(item, filteredItem)))
        ? []
        : filteredData
    );
  }, [filteredData]);

  useEffect(() => {
    setSelected((prev) =>
      prev.filter((item) => filteredData.some((filteredItem) => compareInstrument(item, filteredItem)))
    );
  }, [filteredData]);

  const isSelected = useCallback(
    (item: InstrumentWithSubEquipmentDetailsModelType) => selected.some((i) => compareInstrument(i, item)),
    [selected]
  );
  const getCount = (count: number) => {
    if (count > 0) {
      setSelectedCount(`(${selected.length})`);
    } else {
      setSelectedCount("");
    }
  };

  useEffect(() => {
    setAllSelected(!!selected.length && filteredData.length === selected.length);
  }, [filteredData, selected]);
  useEffect(() => {
    getCount(selected.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const clearSelection = () => {
    setSelected(() => []);
    setSelectedOnlyParents(() => []);
  };

  const onSetSelection = (values: InstrumentWithSubEquipmentDetailsModelType[]) => {
    setSelected(() => [...values]);
  };

  const onAddSelectedParents = (onlyParents: InstrumentWithSubEquipmentDetailsModelType[]) => {
    setSelectedOnlyParents(() => onlyParents);
  };

  return (
    <LogBookSelectContext.Provider
      value={{
        selected,
        onSelect,
        onAllSelect,
        isSelected,
        isAllSelected,
        selectedCount,
        clearSelection,
        onSetSelection,
        selectedOnlyParents,
        onAddSelectedParents
      }}
    >
      {children}
    </LogBookSelectContext.Provider>
  );
};

export default LogbookSelectedWrapper;
