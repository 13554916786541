import { generateID } from "@digitallab/grid-common-components";
import { OwcInput } from "@one/react";
import { FormikProps } from "formik";
import { commonPropsForInputsWithoutValue } from "../../helpers";
import { Module } from "../../../../constants";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";

export const EcrNumberInput = ({ formik }: { formik: FormikProps<{ [name: string]: string }> }) => {
  const classes = {};
  return (
    <OwcInput
      onBlur={formik.handleBlur}
      style={{ width: "100%" }}
      data-testid="action-data-inputs-ecrNumber-input"
      variant="filled"
      fullWidth
      id={generateID.textBoxID(Module.POPUP_FIELDS.ecrNumber, "textbox")}
      name="ecrNumber"
      label={ACTION_LOGS_DATA_MODEL.ecrNumber.value}
      value={formik.values.ecrNumber}
      onInputChange={formik.handleChange}
      {...commonPropsForInputsWithoutValue(classes, formik, "ecrNumber", "ecrNumber", "action-data-inputs")}
    />
  );
};
