import { DATA_MODEL_TABLE } from "@digitallab/grid-common-components";
import { OwcSlideToggle, OwcTextarea } from "@one/react";
import { FormikProps } from "formik";

export const ConfigurationDetailsWithSwitch = ({
  formik,
  isPropagationDisabled,
  editMode
}: {
  formik: FormikProps<{
    logbookConfigurationDetails: { newValue: string; shouldBePublished: boolean };
    action: string;
  }>;
  isPropagationDisabled?: boolean;
  editMode: boolean;
}) => (
  <>
    <div className="owcInputBox" style={{ width: "100%" }}>
      {formik && (
        <OwcTextarea
          id="logbookConfigurationDetails.newValue"
          label={DATA_MODEL_TABLE.configurationDetails.value}
          style={{ minHeight: 136 }}
          rows={8}
          maxLength={500}
          placeholder={DATA_MODEL_TABLE.configurationDetails.value}
          value={formik.values.logbookConfigurationDetails?.newValue}
          onBlur={formik.handleBlur}
          onInputChange={formik.handleChange}
          onInputKeyDown={formik.handleChange}
          assistiveText={isPropagationDisabled ? "keep current status" : ""}
        />
      )}
    </div>
    {!isPropagationDisabled && (
      <OwcSlideToggle
        checked={formik.values.logbookConfigurationDetails?.shouldBePublished}
        onInputChange={(event: { detail: string }) =>
          formik.setFieldValue("logbookConfigurationDetails.shouldBePublished", event.detail)
        }
        id="logbookConfigurationDetails.shouldBePublished"
        disabled={editMode}
      >
        Publish “Configuration details” to Equipment Repository
      </OwcSlideToggle>
    )}
  </>
);
