import React from "react";
import {
  DigitalLabLogbookInstrumentModelType,
  InstrumentWithSubEquipmentDetailsModelType
} from "../../../../models/DigitalLabLogbookInstrumentModelType";

interface LogBookSelectContextType {
  selected: DigitalLabLogbookInstrumentModelType[];
  onSelect: (item: InstrumentWithSubEquipmentDetailsModelType) => void;
  onAllSelect: () => void;
  isSelected: (item: InstrumentWithSubEquipmentDetailsModelType) => boolean;
  isAllSelected: boolean;
  selectedCount: string;
  clearSelection: () => void;
  onSetSelection: (values: InstrumentWithSubEquipmentDetailsModelType[]) => void;
  selectedOnlyParents: InstrumentWithSubEquipmentDetailsModelType[];
  onAddSelectedParents: (onlyParents: InstrumentWithSubEquipmentDetailsModelType[]) => void;
}

export const LogBookSelectContext = React.createContext<LogBookSelectContextType>({
  selected: [],
  onSelect: () => {},
  onAllSelect: () => {},
  isSelected: () => false,
  isAllSelected: false,
  selectedCount: "",
  clearSelection: () => {},
  onSetSelection: () => {},
  selectedOnlyParents: [],
  onAddSelectedParents: () => {}
});
