import { DateTimeDetailValue } from "../../../../components/shared/details-cells/DateTimeDetailValue";
import DetailsCells from "../../../../components/shared/details-cells/DetailsCells";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import { DESCRIPTION_FIELD } from "../../../../utils/constants/descriptionField";
import RunStatusLabel from "../../common-for-log-sheet-and-run-logs/RunStatusLabel";
import {
  ApproverUserName,
  Flex,
  RunLogWrapper,
  CustomDetailsCellStyled,
  MediaTypeCell
} from "../../common-for-log-sheet-and-run-logs/table-common-meta-info";
import ValueOrFallbackText from "../../common-for-log-sheet-and-run-logs/ValueOrFallbackText";
import { changeDateFormat } from "../../helpers";
import RUN_LOGS_DATA_MODEL from "./../../../../utils/constants/runLogsDataModel";
export const RunLogTableMeta = {
  expandable: true,
  ExpandedComponent: ({ item, infoMeta }) => (
    <Flex>
      <DetailsCells Wrapper={RunLogWrapper} item={item} infoMeta={infoMeta} CellWrapper={CustomDetailsCellStyled} />
    </Flex>
  ),
  expandedComponentProps: {
    infoMeta: {
      fields: [
        {
          label: RUN_LOGS_DATA_MODEL.runStartTime.value,
          component: ({ item }) => <DateTimeDetailValue time={item.runStartTime} />
        },
        {
          label: RUN_LOGS_DATA_MODEL.runEndTime.value,
          component: ({ item }) => <DateTimeDetailValue time={item.runEndTime} />
        },
        {
          label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
          field: ["operatorUserName"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.runIdentification.value,
          field: ["runIdentification"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.eLNid.value,
          field: ["eLNid"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.samplesProcessed.value,
          field: ["samplesProcessed"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.numberOfRuns.value,
          field: ["numberOfRuns"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.defectId.value,
          field: ["defectId"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.mediaType.value,
          component: MediaTypeCell
        },
        {
          label: RUN_LOGS_DATA_MODEL.assay.value,
          component: ({ item }) => <span>{item.assay && item.assay.length > 0 ? item.assay.join(", ") : "-"}</span>,
          props: {
            expand: true
          }
        },
        DESCRIPTION_FIELD
      ]
    }
  },
  fields: {
    detailExpander: {
      text: ""
    },
    runStartDate: {
      sortable: true,
      text: RUN_LOGS_DATA_MODEL.runStartDate.value,
      component: ({ item }) => changeDateFormat(item?.runStartDate)
    },
    runEndDate: {
      sortable: true,
      text: RUN_LOGS_DATA_MODEL.runEndDate.value,
      component: ({ item }) => <DateTimeDetailValue date={changeDateFormat(item?.runEndDate)} time={item.runEndTime} />
    },
    equipmentModel: {
      text: COMMON_LOGS_DATA_MODEL.equipmentModel.value
    },
    equipmentNickName: {
      text: COMMON_LOGS_DATA_MODEL.equipmentNickName.value
    },
    equipmentId: {
      text: COMMON_LOGS_DATA_MODEL.equipmentId.value
    },
    serialNumber: {
      text: COMMON_LOGS_DATA_MODEL.serialNumber.value
    },
    runStatus: {
      text: RUN_LOGS_DATA_MODEL.runStatus.value,
      component: RunStatusLabel
    },
    ...ApproverUserName,
    operatorUserName: {
      text: COMMON_LOGS_DATA_MODEL.operatorUserName.value
    },
    runIdentification: {
      text: RUN_LOGS_DATA_MODEL.runIdentification.value
    },
    eLNid: {
      text: RUN_LOGS_DATA_MODEL.eLNid.value
    },
    samplesProcessed: {
      text: RUN_LOGS_DATA_MODEL.samplesProcessed.value
    },
    numberOfRuns: {
      text: RUN_LOGS_DATA_MODEL.numberOfRuns.value
    },
    defectId: {
      text: RUN_LOGS_DATA_MODEL.defectId.value
    },
    tipsUsed: {
      text: RUN_LOGS_DATA_MODEL.tipsUsed.value,
      component: ({ item }) => (
        <ValueOrFallbackText reduxPath={["runLogsForm", "tips"]} item={item.tipsUsed} defaultValue="-" />
      )
    },
    mediaType: {
      text: RUN_LOGS_DATA_MODEL.mediaType.value,
      component: MediaTypeCell
    },
    assay: {
      text: RUN_LOGS_DATA_MODEL.assay.value,
      component: ({ item }) => <span>{item.assay && item.assay.length > 0 ? item.assay.join(", ") : "-"}</span>
    },
    description: {
      text: RUN_LOGS_DATA_MODEL.description.value
    }
  }
};

export const runLogTabletSizes = {
  runStartDate: "0 1 12%",
  equipmentModel: "0 1 13%",
  equipmentNickName: "0 1 13%",
  equipmentId: "0 1 12%",
  serialNumber: "0 1 12%",
  runStatus: "0 1 12%",
  description: "0 1 10%",
  approverUserName: "0 1 5%",
  actionButtons: "0 0 110px"
};

export const runLogRegularSizes = {
  runStartDate: "0 1 12%",
  equipmentModel: "0 1 13%",
  equipmentNickName: "0 1 13%",
  equipmentId: "0 1 12%",
  serialNumber: "0 1 12%",
  runStatus: "0 1 12%",
  description: "0 1 10%",
  approverUserName: "0 1 5%",
  actionButtons: "0 0 110px"
};

export const RunLogTableEquipmentCentricMeta = {
  expandable: true,
  ExpandedComponent: ({ item, infoMeta }) => (
    <Flex>
      <DetailsCells Wrapper={RunLogWrapper} item={item} infoMeta={infoMeta} CellWrapper={CustomDetailsCellStyled} />
    </Flex>
  ),
  expandedComponentProps: {
    infoMeta: {
      fields: [
        {
          label: RUN_LOGS_DATA_MODEL.runStartTime.value,
          component: ({ item }) => <DateTimeDetailValue time={item.runStartTime} />
        },
        {
          label: RUN_LOGS_DATA_MODEL.runEndTime.value,
          component: ({ item }) => <DateTimeDetailValue time={item.runEndTime} />
        },
        {
          label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
          field: ["operatorUserName"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.runIdentification.value,
          field: ["runIdentification"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.eLNid.value,
          field: ["eLNid"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.samplesProcessed.value,
          field: ["samplesProcessed"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.numberOfRuns.value,
          field: ["numberOfRuns"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.defectId.value,
          field: ["defectId"]
        },
        {
          label: RUN_LOGS_DATA_MODEL.mediaType.value,
          component: MediaTypeCell
        },
        {
          label: RUN_LOGS_DATA_MODEL.assay.value,
          component: ({ item }) => <span>{item.assay && item.assay.length > 0 ? item.assay.join(", ") : "-"}</span>,
          props: {
            expand: true
          }
        },
        DESCRIPTION_FIELD
      ]
    }
  },
  fields: {
    detailExpander: {
      text: ""
    },
    runStartDate: {
      sortable: true,
      text: RUN_LOGS_DATA_MODEL.runStartDate.value,
      component: ({ item }) => changeDateFormat(item?.runStartDate)
    },
    runEndDate: {
      sortable: true,
      text: RUN_LOGS_DATA_MODEL.runEndDate.value,
      component: ({ item }) => <DateTimeDetailValue date={changeDateFormat(item?.runEndDate)} time={item.runEndTime} />
    },
    runStatus: {
      text: RUN_LOGS_DATA_MODEL.runStatus.value,
      component: RunStatusLabel
    },
    ...ApproverUserName,
    operatorUserName: {
      text: COMMON_LOGS_DATA_MODEL.operatorUserName.value
    },
    runIdentification: {
      text: RUN_LOGS_DATA_MODEL.runIdentification.value
    },
    eLNid: {
      text: RUN_LOGS_DATA_MODEL.eLNid.value
    },
    samplesProcessed: {
      text: RUN_LOGS_DATA_MODEL.samplesProcessed.value
    },
    numberOfRuns: {
      text: RUN_LOGS_DATA_MODEL.numberOfRuns.value
    },
    gxpReady: {
      text: RUN_LOGS_DATA_MODEL.gxpReady.value
    },
    systemStatus: {
      text: RUN_LOGS_DATA_MODEL.systemStatus.value
    },
    defectId: {
      text: RUN_LOGS_DATA_MODEL.defectId.value
    },
    tipsUsed: {
      text: RUN_LOGS_DATA_MODEL.tipsUsed.value,
      component: ({ item }) => (
        <ValueOrFallbackText reduxPath={["runLogsForm", "tips"]} item={item.tipsUsed} defaultValue="-" />
      )
    },
    mediaType: {
      text: RUN_LOGS_DATA_MODEL.mediaType.value,
      component: MediaTypeCell
    },
    assay: {
      text: RUN_LOGS_DATA_MODEL.assay.value,
      component: ({ item }) => <span>{item.assay && item.assay.length > 0 ? item.assay.join(", ") : "-"}</span>
    },
    description: {
      text: RUN_LOGS_DATA_MODEL.description.value
    }
  }
};

export default RunLogTableMeta;
