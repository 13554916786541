import { OwcTypography } from "@one/react";
import { KEEP_STATUS_VALUE } from "../../../../logs/StepperContainer";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import RunSelectInput from "../../run-logs/run-logs-form/run-data-inputs/RunSelectInput";
import { FormikProps } from "formik";

export const StatusSelect = ({
  formik,
  editMode,
  isPropagationDisabled,
  numberOfSelectedEquipment,
  property,
  options
}: {
  formik: FormikProps<{ [name: string]: { [key: string]: string } }>;
  editMode: boolean;
  isPropagationDisabled: boolean;
  numberOfSelectedEquipment: number;
  property: string;
  options: { key: string; value: string }[];
}) => (
  <div>
    <RunSelectInput
      objectAsValue
      testidPrefix="action-data"
      options={
        numberOfSelectedEquipment > 1
          ? [KEEP_STATUS_VALUE].concat(
              options.map(({ key, value }) => ({
                key,
                value: `Set all to "${value}"`
              }))
            )
          : options
      }
      property={property}
      Selectlabel={ACTION_LOGS_DATA_MODEL[property as keyof typeof ACTION_LOGS_DATA_MODEL].value}
      testid={property}
      propValue="key"
      propLabel="value"
      required
      fullWidth
      disabled={isPropagationDisabled}
    />
    {!editMode && !isPropagationDisabled && formik.values[property]?.key !== KEEP_STATUS_VALUE.key && (
      <OwcTypography variant="body1" style={{ marginLeft: "10px" }}>
        Will be propagated to repository
      </OwcTypography>
    )}
  </div>
);
