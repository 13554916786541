import DLabGrid from "../DLabGrid/DLabGrid";
import React, { useRef, useState, useMemo } from "react";
import { valueOrEmpty } from "../../utils/helpers/text";
import { useCallback } from "react";
import { entryType } from "../../constants";

const ClusterAgGridTableAddFlowFirstStep = ({ meta, data = [], onSetSelection, selected, isEditMode }) => {
  const gridRef = useRef();
  const [totalRows, setTotalRows] = useState(data?.length);
  const [rowPerPage, setRowPerPage] = useState(10);
  const overrideCellRender = (key, defaultObj, metaData) => {
    switch (key) {
      case "gxpReady":
        defaultObj = {
          ...defaultObj,
          cellRenderer: metaData?.cellRenderer
        };
        break;
      case "systemStatus":
        defaultObj = {
          ...defaultObj,
          cellRenderer: metaData?.cellRenderer
        };
        break;
      case "status":
        defaultObj = {
          ...defaultObj,
          cellRenderer: metaData?.cellRenderer
        };
        break;

      default:
        defaultObj = {
          ...defaultObj,
          valueFormatter: (params) => valueOrEmpty(params?.data[key] ?? "-", false, "-")
        };
        break;
    }
    return defaultObj;
  };

  const dLabColumnDef = useMemo(() => {
    let defaultObj;
    let colDef = [];

    for (const key in meta.fields) {
      if (key !== "clusterIdentifier") {
        defaultObj = {
          field: key,
          headerName: meta.fields[key].text,
          autoHeight: true,
          minWidth: 215
        };

        defaultObj = overrideCellRender(key, defaultObj, meta.fields[key]);
      } else {
        defaultObj = {
          ...defaultObj,
          field: key,
          headerName: meta.fields[key].text,
          autoHeight: true,
          minWidth: 100,
          headerCheckboxSelection: () => !isEditMode,
          checkboxSelection: () => !isEditMode,
          showDisabledCheckboxes: true
        };
      }
      colDef.push(defaultObj);
    }
    return colDef;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.fields]);
  const onFilterChanged = (params) => {
    setTotalRows(params?.api?.paginationGetRowCount());
  };

  const onRowDataUpdated = (params) => {
    setTotalRows(params?.api?.paginationGetRowCount());
  };
  const onRowsPerPageChanged = (value) => {
    setRowPerPage(value);
  };

  let attr = {
    paginationConfig: {
      rowsPerPage: rowPerPage,
      total: totalRows
    },
    columnDefs: dLabColumnDef,
    animateRows: true,
    rowExport: false,
    suppressContextMenu: true,
    hiddenByDefault: true,
    rowData: data,
    rowSelection: "multiple",
    groupSelectsChildren: !isEditMode, // toggle based on edit mode
    suppressRowClickSelection: true,
    onFilterChanged,
    onRowDataUpdated
  };
  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    let totalSelected = [];
    if (selectedRows.length > 0) {
      selectedRows.forEach((item) => {
        totalSelected.push({ ...item });
      });
    }
    onSetSelection(totalSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onViewportChanged = (params) => {
    let nodes = params?.api?.getRenderedNodes();

    if (params && params?.api) {
      if (nodes && nodes?.length > 0 && selected?.length > 0) {
        params?.api?.forEachNode((node) => {
          node?.data &&
            selected.forEach((item) => {
              if (item.inventoryId === node?.data?.inventoryId) {
                node?.setSelected(true);
              }
            });
        });
      } else if (nodes && nodes?.length > 0) {
        params?.api?.forEachNode((node) => {
          return node?.setSelected(false);
        });
      }
    }
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      suppressMenu: true,
      sortable: false,
      minWidth: 200
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "",
      maxWidth: 50,
      cellRendererParams: {
        innerRenderer: () => {
          return "";
        },
        suppressCount: true
      },
      pinned: "left"
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getDataPath = useMemo(() => {
    return (data) => {
      return data.clusterParent;
    };
  }, []);
  const detailCellRenderer = useMemo(() => {
    return () => <div>mater detail</div>;
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    params.api.forEachNode((node) => {
      if (!node.data) return;
      if (!isEditMode && selected.length === 0) {
        node.setSelected(true);
        return;
      }

      selected.forEach((item) => {
        if (item.entryType === entryType.cluster) {
          const selected = item.subEquipment?.find((details) => item.inventoryId === details.inventoryId);
          if (selected) {
            node.setSelected(true);
          }
        } else if (item.inventoryId === node.data.inventoryId) {
          node.setSelected(true);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DLabGrid
      {...attr}
      pagination={false}
      suppressPaginationPanel={false}
      defaultColDef={defaultColDef}
      autoGroupColumnDef={autoGroupColumnDef}
      treeData={true}
      animateRows={true}
      detailCellRenderer={detailCellRenderer}
      gridRef={gridRef}
      onRowsPerPageChanged={onRowsPerPageChanged}
      getDataPath={getDataPath}
      onViewportChanged={onViewportChanged}
      onSelectionChanged={onSelectionChanged}
      onFirstDataRendered={onFirstDataRendered}
    />
  );
};

export default ClusterAgGridTableAddFlowFirstStep;
