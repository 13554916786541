import React from "react";
import {
  DigitalLabLogbookInstrumentModelType,
  InstrumentWithSubEquipmentDetailsModelType
} from "../../../models/DigitalLabLogbookInstrumentModelType";
import { StateAndDispatch } from "../../../models/StateAndDispatch";

export type CoverSheetMainPageContextType = {
  handleOpenWorkFlowModel: ({ isCluster, form }: { isCluster?: boolean; form?: string }) => void;
  handleCloseWorkFlowModel: () => void;

  loadLogBookEquipment: (logBookEquip: DigitalLabLogbookInstrumentModelType | null) => void;
  logBookEquipment: DigitalLabLogbookInstrumentModelType | null;
  isClusterForm: boolean;
  formState: string;
  logType: string;
  loadFormType: (type: string) => void;
  loadFormState: (formState: string) => void;
  refreshTable: () => void;
  refreshAfterEditOrNew: boolean;
  allLogType: string;
  loadAllFormType: (type: string) => void;
  allLogHierarchyData: InstrumentWithSubEquipmentDetailsModelType[] | null;
  loadAllLogHierarchyData: (type: InstrumentWithSubEquipmentDetailsModelType[] | null) => void;
} & StateAndDispatch<"refreshCoverSheet", boolean> &
  StateAndDispatch<"handleOpenChange", boolean> &
  StateAndDispatch<"tabValue", string> &
  StateAndDispatch<"openWorkFlowModel", boolean>;

export const CoverSheetMainPageContext = React.createContext<CoverSheetMainPageContextType>({
  refreshCoverSheet: false,
  setRefreshCoverSheet: () => {},
  handleOpenChange: false,
  setHandleOpenChange: () => {},
  tabValue: "",
  setTabValue: () => {},
  openWorkFlowModel: false,
  setOpenWorkFlowModel: () => {},
  handleOpenWorkFlowModel: ({ isCluster = false, form = "" }) => {},
  handleCloseWorkFlowModel: () => {},
  loadLogBookEquipment: (logBookEquip: DigitalLabLogbookInstrumentModelType | null) => {},
  logBookEquipment: null,
  isClusterForm: false,
  formState: "",
  logType: "",
  loadFormType: (type: string) => {},
  loadFormState: (formState: string) => {},
  refreshTable: () => {},
  refreshAfterEditOrNew: false,
  allLogType: "",
  loadAllFormType: (type: string) => {},
  allLogHierarchyData: null,
  loadAllLogHierarchyData: (data: InstrumentWithSubEquipmentDetailsModelType[] | null) => {}
});
