import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ItemDialogFormContext } from "./context";
import { CLUSTER_STEPS } from "../../../../constants";
import { CoverSheetMainPageContext } from "../../../cover-sheet/cover-sheet-main-page-context/context";
import { GET_EXPORT_FILE_TO_ENDOR } from "../../../../gql/logBooksapi";
import { DigitalLabLogbookInstrumentModelType } from "../../../../models/DigitalLabLogbookInstrumentModelType";

export const FILE_STATE = {
  IDLE: "idle",
  PREUPLOAD: "preupload",
  PREUPLOAD_URL: "preuploadUrl",
  UPLOAD: "upload",
  FINISH: "finish",
  MAX_SIZE: "max_size"
};

export const ACTION_TYPE = {
  PREUPLOAD: "PREUPLOAD",
  UPLOAD: "UPLOAD"
};

interface ItemWrapperProps {
  children: React.ReactNode;
  entryDataType?: string;
  setClusterLogStatus?: (status: boolean) => void;
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  openDialog?: boolean;
}

export interface FileType {
  s3FileName?: string;
  file: {
    name: string;
  };
  Description?: string;
}

interface InventoryParams {
  inventoryId?: string;
}

const ItemWrapper = ({
  children,
  entryDataType = "STANDALONE-EQUIPMENT",
  setClusterLogStatus = () => {
    return false;
  },
  setOpenDialog,
  openDialog
}: ItemWrapperProps) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<DigitalLabLogbookInstrumentModelType | null>(null);
  const [subClusterFlag, setSubClusterFlag] = useState(true);
  const [isInventoryId, setIsInventoryId] = useState(false);
  const { inventoryId } = useParams<InventoryParams>();
  const [subComponentOpen, setSubComponentOpen] = useState(false);
  const [selectedSubEquipment, setSelectedSubEquipment] = useState<DigitalLabLogbookInstrumentModelType | null>(null);
  const [activeStep, setActiveStep] = useState(CLUSTER_STEPS[0]);
  const [isStandaloneInvId, setIsStandaloneInvId] = useState("");
  const [rowParam, setRowParam] = useState(null);
  const [equipmentActionObject, setEquipmentActionObject] = useState<DigitalLabLogbookInstrumentModelType | null>(null);
  const [subClusterPendingFlag, setSubClusterPendingFlag] = useState<boolean | null>(null);

  /*For action log link*/
  const [actionLogLinkName, setActionLogLinkName] = useState<string | null>(null);
  const [actionLogLinkUrl, setActionLogLinkUrl] = useState<string | null>(null);
  const [actionLogLinkCurrentIndex, setActionLogLinkCurrentIndex] = useState<number | null>(null);
  const [actionLogLinkCurrentFlag, setActionLogLinkCurrentFlag] = useState<boolean | null>(null);
  const [addLinkFlag, setAddLinkFlag] = useState<boolean>(false);
  /*For action log link*/

  const [propagationSync, setPropagationSync] = useState(false);
  const { setRefreshCoverSheet, handleOpenChange } = useContext(CoverSheetMainPageContext);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [files, setFiles] = useState<File[]>([]);
  const [filesData, setFilesData] = useState<unknown[]>([]);
  useEffect(() => {
    if (typeof inventoryId !== "undefined" && inventoryId !== null) {
      setIsInventoryId(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = (theItem: React.SetStateAction<DigitalLabLogbookInstrumentModelType | null>) => {
    setClusterLogStatus(false);
    setOpen(true);
    setItem(theItem);
  };

  const handleClose = () => {
    setOpen(false);
    setSubComponentOpen(false);
    setItem(null);
    setActiveStep(CLUSTER_STEPS[0]);
    setClusterLogStatus(false);
    if (openDialog) {
      setOpenDialog?.(false);
    }
    if (isInventoryId && setRefreshCoverSheet !== undefined) {
      (setRefreshCoverSheet as React.Dispatch<React.SetStateAction<boolean>>)(false);
    }
  };

  const uploadAppsync = async (client: any, file: FileType, id: string, user: string, type = ACTION_TYPE.PREUPLOAD) => {
    const { data } = await client.query({
      query: GET_EXPORT_FILE_TO_ENDOR,
      fetchPolicy: "no-cache",
      variables: {
        actionType: type,
        targetModule: "LOGBOOK",
        s3id: file.s3FileName || file.file.name,
        fileDescription: file["Description"],
        metadata: `{"parentId": "${id}", "userId": "${user}"}`
      }
    });
    return data.getExportFileToEndor;
  };

  useEffect(() => {
    handleOpen(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOpenChange]);

  const onLoadCurrentRowParam = (item: any) => {
    setRowParam(() => item);
  };

  return (
    <ItemDialogFormContext.Provider
      value={{
        open,
        handleOpen,
        files,
        setFiles,
        filesData,
        setFilesData,
        uploadAppsync,
        handleClose,
        item,
        isInventoryId,
        subComponentOpen,
        setSubComponentOpen,
        activeStep,
        setActiveStep,
        setIsInventoryId,
        isStandaloneInvId,
        setIsStandaloneInvId,
        subClusterFlag,
        setSubClusterFlag,
        selectedSubEquipment,
        setSelectedSubEquipment,
        rowParam,
        onLoadCurrentRowParam,
        isSubmitDisabled,
        setIsSubmitDisabled,
        actionLogLinkName,
        setActionLogLinkName,
        actionLogLinkUrl,
        setActionLogLinkUrl,
        actionLogLinkCurrentIndex,
        setActionLogLinkCurrentIndex,
        actionLogLinkCurrentFlag,
        setActionLogLinkCurrentFlag,
        addLinkFlag,
        setAddLinkFlag,
        propagationSync,
        setPropagationSync,
        equipmentActionObject,
        setEquipmentActionObject,
        subClusterPendingFlag,
        setSubClusterPendingFlag
      }}
    >
      {children}
    </ItemDialogFormContext.Provider>
  );
};

export default ItemWrapper;
