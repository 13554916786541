import { generateID } from "@digitallab/grid-common-components";
import { OwcInput } from "@one/react";
import { FormikProps } from "formik";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import { commonPropsForInputsWithoutValue } from "../../helpers";
import { Module } from "../../../../constants";

export const OperatorUserNameInput = ({ formik }: { formik: FormikProps<{ [name: string]: string }> }) => {
  const classes = {};
  return (
    <OwcInput
      style={{ width: "100%" }}
      onBlur={formik.handleBlur}
      data-testid="action-data-inputs-operator-user-name-input"
      color="primary"
      variant="filled"
      id={generateID.textBoxID(Module.POPUP_FIELDS.actionOperatorUserName, "textbox")}
      name={COMMON_LOGS_DATA_MODEL.operatorUserName.key}
      label={COMMON_LOGS_DATA_MODEL.operatorUserName.value}
      value={formik.values.operatorUserName}
      onInputChange={formik.handleChange}
      {...commonPropsForInputsWithoutValue(
        classes,
        formik,
        COMMON_LOGS_DATA_MODEL.operatorUserName.key,
        "operator-user-name",
        "action-data-inputs"
      )}
    />
  );
};
