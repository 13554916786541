import React from "react";
import { FileType } from "./ItemWrapper";
import { CLUSTER_STEPS, ClusterStepType } from "../../../../constants";
import { WithApolloClient } from "react-apollo";
import {
  DigitalLabLogbookInstrumentModelType,
  InstrumentWithSubEquipmentDetailsModelType
} from "../../../../models/DigitalLabLogbookInstrumentModelType";
import { StateAndDispatch } from "../../../../models/StateAndDispatch";

export type ItemDialogFormContextType = {
  open: boolean;
  handleOpen: (theItem: React.SetStateAction<null | DigitalLabLogbookInstrumentModelType>) => void;
  uploadAppsync: (
    client: WithApolloClient<{}>,
    file: FileType,
    id: string,
    user: string,
    type?: string
  ) => Promise<unknown>;
  handleClose: () => void;
  item: DigitalLabLogbookInstrumentModelType | null;
  rowParam: InstrumentWithSubEquipmentDetailsModelType | null;
  onLoadCurrentRowParam: (param: InstrumentWithSubEquipmentDetailsModelType) => void;
} & StateAndDispatch<"files", File[]> &
  StateAndDispatch<"filesData", unknown[]> &
  StateAndDispatch<"subComponentOpen", boolean> &
  StateAndDispatch<"activeStep", ClusterStepType> &
  StateAndDispatch<"isInventoryId", boolean> &
  StateAndDispatch<"isStandaloneInvId", string> &
  StateAndDispatch<"subClusterFlag", boolean> &
  StateAndDispatch<"selectedSubEquipment", DigitalLabLogbookInstrumentModelType | null> &
  StateAndDispatch<"isSubmitDisabled", boolean> &
  StateAndDispatch<"actionLogLinkName", string | null> &
  StateAndDispatch<"actionLogLinkUrl", string | null> &
  StateAndDispatch<"actionLogLinkCurrentIndex", number | null> &
  StateAndDispatch<"actionLogLinkCurrentFlag", boolean | null> &
  StateAndDispatch<"addLinkFlag", boolean> &
  StateAndDispatch<"propagationSync", boolean> &
  StateAndDispatch<"equipmentActionObject", DigitalLabLogbookInstrumentModelType | null> &
  StateAndDispatch<"subClusterPendingFlag", boolean | null>;

const defaultContext: ItemDialogFormContextType = {
  handleOpen: () => {},
  open: false,
  files: [],
  setFiles: () => {},
  filesData: [],
  setFilesData: () => {},
  uploadAppsync: async () => Promise.resolve(),
  handleClose: () => {},
  item: null,
  isInventoryId: false,
  subComponentOpen: false,
  setSubComponentOpen: () => {},
  activeStep: CLUSTER_STEPS[0],
  setActiveStep: () => {},
  setIsInventoryId: () => {},
  isStandaloneInvId: "",
  setIsStandaloneInvId: () => {},
  subClusterFlag: false,
  setSubClusterFlag: () => {},
  selectedSubEquipment: null,
  setSelectedSubEquipment: () => {},
  rowParam: null,
  onLoadCurrentRowParam: () => {},
  isSubmitDisabled: false,
  setIsSubmitDisabled: () => {},
  actionLogLinkName: null,
  setActionLogLinkName: () => {},
  actionLogLinkUrl: null,
  setActionLogLinkUrl: () => {},
  actionLogLinkCurrentIndex: null,
  setActionLogLinkCurrentIndex: () => {},
  actionLogLinkCurrentFlag: null,
  setActionLogLinkCurrentFlag: () => {},
  addLinkFlag: false,
  setAddLinkFlag: () => {},
  propagationSync: false,
  setPropagationSync: () => {},
  equipmentActionObject: null,
  setEquipmentActionObject: () => {},
  subClusterPendingFlag: null,
  setSubClusterPendingFlag: () => {}
};

export const ItemDialogFormContext = React.createContext<ItemDialogFormContextType>(defaultContext);
