import { useFormikContext } from "formik";
import { formStates, formTypes } from "../../../../constants";
import { Mainbox, Title } from "../../log-book-item-form-dialog/FormsStyled";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import LOGBOOK_LABEL from "../../../../utils/constants/logbookLabel";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import { generateID, ActionTypeSelect } from "@digitallab/grid-common-components";
import { OwcTypography } from "@one/react";
import RunTimeInput from "../../run-logs/run-logs-form/run-data-inputs/RunTimeInput";
import { CoverSheetMainPageContext } from "../../../cover-sheet/cover-sheet-main-page-context/context";
import FileList from "./FileList";
import { LinksList } from "./LinksList";
import { GET_LIST_OF_ACTION_TYPES } from "../../../../gql/logBooksapi";
import { ActionDateInput } from "./ActionDateInput";
import { OperatorUserNameInput } from "./OperatorUserNameInput";
import { EcrNumberInput } from "./EcrNumberInput";
import { QualificationStatusSelect } from "./QualificationStatusSelect";
import { SystemStatusSelect } from "./SystemStatusSelect";
import { DescriptionTestArea } from "./DescriptionTestArea";
import { SoftwareInputWithSwitch } from "./SoftwareInputWithSwitch";
import { ConfigurationDetailsWithSwitch } from "./ConfigurationDetailsWithSwitch";
import { getAppSyncBookingClient } from "../../../../booking_client";
import { changeDateFormat } from "../../helpers";

const attachmentSites = process.env.REACT_APP_ATTACHMENT_SITES || [];

const ActionDataInputsBox = ({ isPropagationDisabled, numberOfSelectedEquipment }) => {
  const formik = useFormikContext();
  const [actionTime, setActionTime] = useState(null);
  const [actionTypes, setActionTypes] = useState([]);
  const [actionTypesError, setActionTypesError] = useState();

  const { formState } = useContext(CoverSheetMainPageContext);
  const editMode = formState === formStates?.EDITABLE_FORM;
  const storeUserSite = useSelector((store) => store.user?.site);

  useEffect(() => {
    const getActionTypes = async () => {
      try {
        const result = await getAppSyncBookingClient().query({
          query: GET_LIST_OF_ACTION_TYPES,
          fetchPolicy: "no-cache"
        });
        setActionTypes(result?.data?.__type?.enumValues?.map((val) => val?.name) || []);
      } catch (err) {
        console.error(err.message);
        setActionTypesError(err.message);
      }
    };

    getActionTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tempActionTime;
    if (!editMode) {
      tempActionTime = formik.values.actionTime
        ? new Date(changeDateFormat(new Date()) + " " + formik.values.actionTime)
        : null;
    } else {
      tempActionTime = formik.values.actionTime
        ? new Date(changeDateFormat(new Date()) + " " + formik.values.actionTime)
        : null;

      formik.setFieldValue("updatedSoftwareVersion.shouldBePublished", false, true);
    }
    setActionTime(tempActionTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.actionTime]);

  return (
    <>
      <Mainbox>
        <Title data-testid="action-data-title">{LOGBOOK_LABEL.action_data}</Title>
        <ActionDateInput formik={formik} />
        <RunTimeInput
          property={ACTION_LOGS_DATA_MODEL.actionTime.key}
          label={ACTION_LOGS_DATA_MODEL.actionTime.value}
          required={true}
          testid="action-start-time"
          timeObj={actionTime}
          setTimeObj={setActionTime}
          maxTime={new Date().getTime()}
          logType={formTypes?.ACTION_LOG}
        />
        <ActionTypeSelect formik={formik} error={actionTypesError} name="action" actionTypes={actionTypes} />
        <OperatorUserNameInput formik={formik} />
        <EcrNumberInput formik={formik} />
        <QualificationStatusSelect
          formik={formik}
          editMode={editMode}
          isPropagationDisabled={isPropagationDisabled}
          numberOfSelectedEquipment={numberOfSelectedEquipment}
        />
        <SystemStatusSelect
          formik={formik}
          editMode={editMode}
          isPropagationDisabled={isPropagationDisabled}
          numberOfSelectedEquipment={numberOfSelectedEquipment}
        />

        <div style={{ gridColumn: "span 2" }}>
          <DescriptionTestArea formik={formik} />
          <SoftwareInputWithSwitch formik={formik} disabled={editMode} isPropagationDisabled={isPropagationDisabled} />
        </div>
        <div style={{ gridColumn: "span 2" }}>
          <ConfigurationDetailsWithSwitch
            formik={formik}
            isPropagationDisabled={isPropagationDisabled}
            editMode={editMode}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gridColumn: "1/5" }}>
          <OwcTypography
            variant="subtitle1"
            id={generateID.UUID("AddLinkHeading", `addLinkHeading`, "type_title")}
            style={{ padding: "10px 0" }}
          >
            <strong>External document and file links</strong>
          </OwcTypography>

          <div style={{ display: "flex", gap: "8px" }}>
            <LinksList />
            {attachmentSites.includes(storeUserSite) ? <FileList /> : null}
          </div>
        </div>
      </Mainbox>
    </>
  );
};

export default ActionDataInputsBox;
