import { generateID } from "@digitallab/grid-common-components";
import { OwcDatepicker } from "@one/react";
import { FormikProps } from "formik";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import { Module } from "../../../../constants";

export const ActionDateInput = ({ formik }: { formik: FormikProps<{ [name: string]: string }> }) => {
  const dateValue = (value: { detail: Date }) => {
    if (value.detail instanceof Date || value.detail === null) {
      formik.setFieldValue("actionDate", value.detail, true);
    }
  };

  return (
    <OwcDatepicker
      style={{ width: "100%" }}
      autoClose
      inputVariant="filled"
      onBlur={formik.handleBlur}
      format="dd-MMM-yyyy"
      data-testid="action-data-inputs-action-date-input"
      id={generateID.UUID(Module.POPUP_FIELDS.actionDate, 1, "datePicker")}
      name="actionDate"
      variant="filled"
      maxDate={new Date().getTime()}
      label={`${ACTION_LOGS_DATA_MODEL.actionDate.value} *`}
      value={
        typeof formik.values.actionDate === "string" ? new Date(formik.values.actionDate) : formik.values.actionDate
      }
      onValueChange={dateValue}
      invalidDateMessage={"Invalid date format"}
      required={true}
      requiredDateMessage={formik.errors.actionDate}
    >
      {formik.errors.actionDate && <span slot="error-text">{formik.errors.actionDate}</span>}
    </OwcDatepicker>
  );
};
