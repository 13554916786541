import { useFormikContext } from "formik";
import { formTypes, formStates, equipmentStatus } from "../../../../constants";
import { Mainbox, Title } from "../../log-book-item-form-dialog/FormsStyled";
import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import LOGBOOK_LABEL from "../../../../utils/constants/logbookLabel";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import { ActionTypeSelect, generateID } from "@digitallab/grid-common-components";
import { useEffect } from "react";
import { OwcTypography } from "@one/react";
import RunTimeInput from "../../run-logs/run-logs-form/run-data-inputs/RunTimeInput";
import { CoverSheetMainPageContext } from "../../../cover-sheet/cover-sheet-main-page-context/context";
import { logSheetClusterClear } from "./log-sheet-clear-suggestion";
import { find } from "lodash";
import { ItemDialogFormContext } from "../../log-book-item-form-dialog/item-context/context";
import { LogBookSelectContext } from "../../log-book-table/logBooksSelectAll/context";
import FileList from "./FileList";
import { GET_LIST_OF_ACTION_TYPES } from "../../../../gql/logBooksapi";
import { ActionDateInput } from "./ActionDateInput";
import { OperatorUserNameInput } from "./OperatorUserNameInput";
import { EcrNumberInput } from "./EcrNumberInput";
import { DescriptionTestArea } from "./DescriptionTestArea";
import { SoftwareInputWithSwitch } from "./SoftwareInputWithSwitch";
import { LinksList } from "./LinksList";
import { getAppSyncBookingClient } from "../../../../booking_client";
import { changeDateFormat } from "../../helpers";
const attachmentSites = process.env.REACT_APP_ATTACHMENT_SITES || [];

const ActionDataInputsBoxCluster = ({ equipmentDetail }) => {
  const formik = useFormikContext();
  const [actionTypes, setActionTypes] = useState([]);
  const [actionTypesError, setActionTypesError] = useState();
  const [actionTime, setActionTime] = useState(null);
  const { logType, formState } = useContext(CoverSheetMainPageContext);
  const storeUserSite = useSelector((store) => store.user?.site);
  const user = useSelector((state) => state.user);
  const { selected } = useContext(LogBookSelectContext);
  const { subClusterPendingFlag, setSubClusterPendingFlag } = useContext(ItemDialogFormContext);
  const editMode = formState === formStates?.EDITABLE_FORM;

  useEffect(() => {
    const getActionTypes = async () => {
      try {
        const result = await getAppSyncBookingClient().query({
          query: GET_LIST_OF_ACTION_TYPES,
          fetchPolicy: "no-cache"
        });
        const receivedActionTypes = result.data.__type.enumValues.map((val) => val.name);
        if (receivedActionTypes.length === 0) throw new Error("No action types received");
        setActionTypes(receivedActionTypes);
      } catch (err) {
        console.error(err.message);
        setActionTypesError(err.message);
      }
    };

    getActionTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logType === formTypes?.ACTION_LOG) {
      if (formState !== formStates?.EDITABLE_FORM) {
        logSheetClusterClear({ formik, user, equipmentDetail });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pendingSub = find(selected, {
      status: equipmentStatus?.pending?.key
    });
    setSubClusterPendingFlag(pendingSub ? true : false);
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    let tempActionTime;
    if (formState !== formStates?.EDITABLE_FORM) {
      tempActionTime = formik.values.actionTime
        ? new Date(changeDateFormat(new Date()) + " " + formik.values.actionTime)
        : null;
    } else {
      tempActionTime = formik.values.actionTime
        ? new Date(changeDateFormat(new Date()) + " " + formik.values.actionTime)
        : null;
    }
    setActionTime(tempActionTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.actionTime]);

  return (
    <>
      <Mainbox>
        <Title data-testid="action-data-title">{LOGBOOK_LABEL.action_data}</Title>
        <ActionDateInput formik={formik} />

        <RunTimeInput
          property={ACTION_LOGS_DATA_MODEL.actionTime.key}
          label={ACTION_LOGS_DATA_MODEL.actionTime.value}
          testid="run-start-time"
          timeObj={actionTime}
          setTimeObj={setActionTime}
          required={true}
          maxTime={new Date().getTime()}
          logType={formTypes?.ACTION_LOG}
        />
        <ActionTypeSelect formik={formik} error={actionTypesError} name="action" actionTypes={actionTypes} />

        <OperatorUserNameInput formik={formik} />
        <div style={{ gridColumn: "span 1" }}>
          <EcrNumberInput formik={formik} />
        </div>
        <div style={{ gridColumn: "span 3" }}>
          <DescriptionTestArea formik={formik} />
        </div>
      </Mainbox>
      <SoftwareInputWithSwitch formik={formik} disabled={editMode || subClusterPendingFlag} />
      <div style={{ display: "flex", flexDirection: "column", gridColumn: "1/5" }}>
        <OwcTypography
          variant="subtitle1"
          id={generateID.UUID("AddLinkHeading", `addLinkHeading`, "type_title")}
          style={{ padding: "10px 0" }}
        >
          <strong>External document and file links</strong>
        </OwcTypography>
        <div style={{ display: "flex", gap: "8px" }}>
          <LinksList />

          {attachmentSites.includes(storeUserSite) ? <FileList /> : null}
        </div>
      </div>
    </>
  );
};

export default ActionDataInputsBoxCluster;
