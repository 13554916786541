import React, { useContext, useRef, useState, useMemo, useCallback } from "react";
import { changeActionTypeToDisplay, EN_DASH } from "@digitallab/grid-common-components";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { CustomFilterContext } from "../custom-filter/context";
import { SearchTextContext } from "../log-book-item-fillter/suggester/search-context/context";
import { CircularProgress } from "@mui/material";
import COMMON_LOGS_DATA_MODEL from "./../../../utils/constants/commonLogsDataModel";
import ACTION_LOGS_DATA_MODEL from "./../../../utils/constants/actionLogsDataModel";
import RUN_LOGS_DATA_MODEL from "./../../../utils/constants/runLogsDataModel";
import { RUN_STATUS_FIELD, entryType, formTypes, logViews } from "../../../constants";
import DetailCellRenderer from "../../../components/shared/DetailCellRenderer";
import DLabGrid from "../../../components/DLabGrid/DLabGrid";
import { ItemDialogFormContext } from "../log-book-item-form-dialog/item-context/context";
import ClusterLogBookSubComponentActions from "./ClusterLogBookSubComponentActions";
import { OwcChip, OwcIconButton } from "@one/react";
import MenuList from "./MenuList";
import { useEffect } from "react";
import CustomChip from "../../../components/shared/CustomChip";
import { stringComparator, valueOrEmpty, getEnv, sortLogs } from "../../../utils/helpers/text";
import { getContainerHeight } from "../../../utils/helpers/fetching";
import moment from "moment";
import { UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS } from "../../../gql/logBooksapi";
import { withApollo } from "react-apollo";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadUserInfo } from "../../user/redux/actions";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { isEqual } from "date-fns";
const currentEnv = getEnv();

const LogBookTable = ({ dataTestId, meta, type, userDetails, client, loadUserInfo }) => {
  const {
    nextToken,
    data,
    runData,
    actionData,
    fetching,
    dispatchAction: paginationDispatch
  } = useContext(PaginationContext);

  const { inventoryId } = useParams();
  const {
    inputsState: { dateFrom, dateTo, signee, signeeChangeLog },
    gridFilter,
    dispatchAction
  } = useContext(CustomFilterContext);
  const { refreshAfterEditOrNew, loadAllFormType, logType } = useContext(CoverSheetMainPageContext);
  const remainingHeight = 200; //  header + footer + pageHeader
  const { searchText, onLastLogListData, lastSearchedValue, logListData } = useContext(SearchTextContext);
  const [showMenu, setShowMenu] = useState(false);
  const [currentParams, setCurrentParams] = useState(null);

  const [gridApi, setGridApi] = useState(null);
  const detailRowAutoHeight = true;
  const rowStyle = { background: "var(--one-color-cobas-accent-gray-100)" };
  const gridRef = useRef();
  const { isInventoryId, setSubComponentOpen, setSelectedSubEquipment, onLoadCurrentRowParam } =
    useContext(ItemDialogFormContext);
  const [attributes, setAttributes] = useState(null);
  const [containerHeight, setContainerHeight] = useState(getContainerHeight(currentEnv, remainingHeight));
  const tempRowData = useRef([]); // CR: why do we keep 2 copies of data, one here and one in SearchContext.logListData ?
  window.addEventListener("resize", function () {
    setContainerHeight(getContainerHeight(currentEnv, remainingHeight));
  });
  useEffect(() => {
    if (gridRef && gridRef?.current?.api) {
      gridRef.current.api.setQuickFilter(searchText);
      let rowData = [];
      gridRef?.current?.api?.forEachNodeAfterFilterAndSort((node) => rowData.push(node.data));
      if (gridRef && gridRef?.current?.api && rowData.length === 0) {
        gridRef.current.api.showNoRowsOverlay();
      } else if (gridRef && gridRef?.current?.api) {
        gridRef.current.api.hideOverlay();
      }
      onLastLogListData(rowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  useEffect(() => {
    tempRowData.current = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, signee, signeeChangeLog, refreshAfterEditOrNew]);

  useEffect(() => {
    if (gridFilter === false && gridApi) {
      // gridRef.current.api.resetQuickFilter();
      gridRef.current.api.setFilterModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFilter]);

  //CR: also as this function is big, unit test would be recommended, as such it cannot be an anonymous function (either exposed, or moved to another file)
  //function is larger than 1 screen (Code Smell) yet has no global description of what this function does
  useEffect(() => {
    let tempArray = [];
    let allLogData = [];

    // data- object from reducer, containing response data from fetchOne(with nextToken)
    // tempRowData - ? it is updated .current = updatedTempArray
    if (tempRowData?.current.length > 0 || data.length > 0) {
      if (
        tempRowData.current.length > 0 &&
        ((logType === formTypes.RUN_LOG && "runStartDate" in tempRowData.current[0]) ||
          (logType === formTypes.ACTION_LOG && "actionDate" in tempRowData.current[0]))
      ) {
        tempArray = [...tempArray, ...tempRowData?.current];
      } else if (
        (logType === formTypes.RUN_LOG && "runStartDate" in data[0]) ||
        (logType === formTypes.ACTION_LOG && "actionDate" in data[0])
      ) {
        tempArray = [...tempArray, ...data];
      }
    }

    // if data was edited, ie change of data was noticed, but no fetch is needed
    if (!fetching) {
      if (runData?.length > 0) {
        allLogData = [...allLogData, ...runData];
      }
      if (actionData?.length > 0) {
        allLogData = [...allLogData, ...actionData];
      }
    }

    if (allLogData?.length > 0) {
      const allLogMappedData = allLogData.map((object) => {
        return {
          ...object,
          logType: object?.runStartDate ? "Run" : "Action",
          allLogDate: object?.runStartDate ? object?.runStartDate : object?.actionDate,
          typeSpecificDetails: object?.runStartDate ? RUN_STATUS_FIELD[object?.runStatus] : object?.action?.value
        };
      });
      let allLogMappedSortData = sortLogs(allLogMappedData);
      tempArray = [...tempArray, ...allLogMappedSortData];
    }

    let updatedTempArray = tempArray?.map((log) => {
      let updatedLog = { ...log };
      updatedLog.equipmentNickName = log?.equipmentNickName ?? log?.equipmentDetails?.equipmentNickName ?? "";
      updatedLog.tipsUsed = updatedLog?.tipsUsed ? updatedLog?.tipsUsed : null;
      return updatedLog;
    });
    tempRowData.current = updatedTempArray;
    // updating Rows Data Array in state
    onLastLogListData(tempRowData.current);

    // fetching is important due to allData logs, otherwise we reset tempRowData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fetching, meta.fields]);
  //CR: the eslint warning is disabled, but warnings are here for good reasons... (Also a code smell)

  const mediaTypeGetter = useCallback((params) => {
    const mediaTypeArray = params.data?.mediaType?.map((x) => x.value);
    let mediaTypeString = mediaTypeArray?.join(", ") || null;
    mediaTypeString = mediaTypeString === null || !mediaTypeString ? "-" : mediaTypeString;
    return mediaTypeString;
  }, []);
  const assayGetter = useCallback((params) => {
    const assayArray = params.data?.assay ?? [];
    let assayString = assayArray.join(", ");
    assayString = assayString === null || !assayString ? "-" : assayString;
    return assayString;
  }, []);

  const gxpReadyRender = useCallback((params) => {
    const textValue = valueOrEmpty(
      entryType.cluster === params?.data?.equipmentDetails?.entryType
        ? params?.data?.gxpReadyCluster?.value
        : params?.data?.gxpReady?.value,
      false,
      "-"
    );
    return textValue === "-" ? "-" : <OwcChip outlined>{textValue}</OwcChip>;
  }, []);

  const systemStatusRender = useCallback((params) => {
    const textValue = valueOrEmpty(
      entryType.cluster === params?.data?.equipmentDetails?.entryType
        ? params?.data?.systemStatusCluster?.value
        : params?.data?.systemStatus?.value,
      false,
      "-"
    );
    return textValue === "-" ? "-" : <OwcChip outlined>{textValue}</OwcChip>;
  }, []);
  const runStatusRenderer = useCallback((params) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%"
          }}
        >
          {params.value !== null ? (
            <CustomChip
              outlined={true}
              style={{
                minHeight: "22px",
                color:
                  params.value === "RUN_ABORT" || params.value === "USER_ABORT"
                    ? "var(--one-color-red-a-300)"
                    : params.value === "PROCESSING"
                    ? "var(--one-color-cobas-accent-orange-400)"
                    : "var(--one-color-cobas-green-600)"
              }}
              text={RUN_STATUS_FIELD[params.value]}
            />
          ) : (
            "-"
          )}
        </div>
      </>
    );
  }, []);
  const subEquipmentRender = useCallback((params) => {
    return (
      <ClusterLogBookSubComponentActions
        dataTestId={dataTestId}
        item={params?.data}
        setSubComponentOpen={setSubComponentOpen}
        setSelectedSubEquipment={setSelectedSubEquipment}
        onLoadCurrentRowParam={onLoadCurrentRowParam}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const actionRender = useCallback((params) => {
    return (
      <OwcIconButton
        flat
        type="outlined"
        icon="more_vertical"
        onClick={() => {
          if (params?.data?.logType) {
            loadAllFormType(params?.data?.logType === "Run" ? formTypes?.RUN_LOG : formTypes?.ACTION_LOG);
          }
          setShowMenu(true);
          setCurrentParams(params);
        }}
        id={params?.data?.id}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterParams = {
    filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
    inRangeFloatingFilterDateFormat: "DD-MMM-YYYY",
    suppressAndOrCondition: true,
    defaultJoinOperator: "OR",
    buttons: ["reset", "apply"],
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      let cellDate = new Date(cellValue);
      if (isEqual(cellDate, filterLocalDateAtMidnight)) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    }
  };
  const overrideCellRender = (key, defaultObj) => {
    switch (key) {
      case COMMON_LOGS_DATA_MODEL.runStartDate.key:
        defaultObj = {
          ...defaultObj,
          pinned: "left",
          lockVisible: true,
          lockPinned: true,
          filter: "agDateColumnFilter",
          filterParams,
          valueFormatter: (params) => {
            return moment(params?.data[key]).format("DD-MMM-YYYY");
          },
          filterValueGetter: (params) => {
            return moment(params?.data[key]).format("DD-MMM-YYYY");
          }
        };
        break;
      case COMMON_LOGS_DATA_MODEL.runEndDate.key:
        defaultObj = {
          ...defaultObj,
          filter: "agDateColumnFilter",
          filterParams,
          valueFormatter: (params) => {
            return moment(params?.data[key]).format("DD-MMM-YYYY");
          },
          filterValueGetter: (params) => {
            return moment(params?.data[key]).format("DD-MMM-YYYY");
          }
        };
        break;
      case COMMON_LOGS_DATA_MODEL.tipsUsed.key:
        defaultObj = {
          ...defaultObj,
          filterParams,
          cellRenderer: (params) => {
            return params?.value?.value ?? EN_DASH;
          },
          filterValueGetter: (params) => {
            return params?.data[key]?.value;
          }
        };
        break;
      case "actionDate":
        defaultObj = {
          ...defaultObj,
          filter: "agDateColumnFilter",
          filterParams,
          pinned: "left",
          lockVisible: true,
          lockPinned: true,
          valueFormatter: (params) => {
            return moment(params?.data[key]).format("DD-MMM-YYYY");
          },
          filterValueGetter: (params) => {
            return moment(params?.data[key]).format("DD-MMM-YYYY");
          }
        };
        break;
      case "allLogDate":
        defaultObj = {
          ...defaultObj,
          filter: "agDateColumnFilter",
          filterParams,
          pinned: "left",
          lockVisible: true,
          lockPinned: true,
          valueFormatter: (params) => {
            return moment(params?.data[key]).format("DD-MMM-YYYY");
          },
          filterValueGetter: (params) => {
            return moment(params?.data[key]).format("DD-MMM-YYYY");
          }
        };
        break;
      case COMMON_LOGS_DATA_MODEL.subEquipment.key:
        defaultObj = {
          ...defaultObj,
          cellRenderer: subEquipmentRender,
          filterValueGetter: (params) => {
            let equipArray =
              params?.data?.subEquipment !== undefined && params?.data?.subEquipment ? params?.data?.subEquipment : [];

            return equipArray.length < 10 ? `0${equipArray.length}` : equipArray.length;
          }
        };

        break;
      case ACTION_LOGS_DATA_MODEL.gxpReady.key:
        defaultObj = {
          ...defaultObj,
          cellRenderer: gxpReadyRender,
          filterValueGetter: (params) => {
            const textValue = valueOrEmpty(
              entryType.cluster === params?.data?.equipmentDetails?.entryType
                ? params?.data?.gxpReadyCluster?.value
                : params?.data?.gxpReady?.value,
              false,
              null
            );
            return textValue;
          },
          comparator: stringComparator
        };
        break;
      case ACTION_LOGS_DATA_MODEL.actionType.key:
        defaultObj = {
          ...defaultObj,
          cellRenderer: (params) => <>{changeActionTypeToDisplay(params.data.action.value)}</>,
          valueGetter: (params) => valueOrEmpty(params?.data[key]?.value, false, "-")
        };
        break;
      case ACTION_LOGS_DATA_MODEL.systemStatus.key:
        defaultObj = {
          ...defaultObj,
          cellRenderer: systemStatusRender,
          filterValueGetter: (params) => {
            const textValue = valueOrEmpty(
              entryType.cluster === params?.data?.equipmentDetails?.entryType
                ? params?.data?.systemStatusCluster?.value
                : params?.data?.systemStatus?.value,
              false,
              null
            );
            return textValue;
          },
          comparator: stringComparator
        };
        break;
      case "mediaType":
        defaultObj = {
          ...defaultObj,
          valueGetter: mediaTypeGetter
        };

        break;
      case RUN_LOGS_DATA_MODEL.runStatus.key:
        defaultObj = {
          ...defaultObj,
          cellRenderer: runStatusRenderer,
          filterValueGetter: (params) => {
            return RUN_STATUS_FIELD[params?.data[key]];
          }
        };

        break;
      case "detailExpander":
        defaultObj = {
          ...defaultObj,
          cellRenderer: "agGroupCellRenderer",
          width: 40,
          maxWidth: 40,
          pinned: "left",
          lockVisible: true,
          lockPinned: true
        };

        break;
      case "assay":
        defaultObj = {
          ...defaultObj,
          valueGetter: assayGetter
        };
        break;
      case "description":
        defaultObj = {
          ...defaultObj,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains"],
            defaultToNothingSelected: true,
            buttons: ["reset", "apply"]
          }
        };
        break;
      case COMMON_LOGS_DATA_MODEL.serialNumber.key:
        defaultObj = {
          ...defaultObj,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains", "blank"],
            buttons: ["reset", "apply"],
            defaultToNothingSelected: true,
            defaultJoinOperator: "OR"
          },
          valueGetter: (params) => valueOrEmpty(params?.data[key] ?? "-", false, "-")
        };
        break;
      case COMMON_LOGS_DATA_MODEL.equipmentId.key:
        defaultObj = {
          ...defaultObj,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains", "blank"],
            buttons: ["reset", "apply"],
            defaultToNothingSelected: true,
            defaultJoinOperator: "OR"
          },
          valueGetter: (params) => valueOrEmpty(params?.data[key] ?? "-", false, "-")
        };
        break;
      case COMMON_LOGS_DATA_MODEL.equipmentNickName.key:
        defaultObj = {
          ...defaultObj,
          valueGetter: (params) => valueOrEmpty(params?.data[key] ?? "-", false, "-"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains", "blank"],
            buttons: ["reset", "apply"],
            defaultToNothingSelected: true,
            defaultJoinOperator: "OR"
          }
        };
        break;
      default:
        defaultObj = {
          ...defaultObj,
          valueGetter: (params) => valueOrEmpty(params?.data[key] ?? "-", false, "-")
        };
        break;
    }
    return defaultObj;
  };
  const dLabColumnDef = useMemo(() => {
    let defaultObj;
    let colDef = [];

    for (const key in meta.fields) {
      if (key !== "actionButtons") {
        defaultObj = {
          field: key,
          headerName: meta.fields[key].text,
          sortable: meta.fields[key].sortable,
          filter: meta.fields[key].filter,
          filterParams: {
            defaultToNothingSelected: true
          },
          floatingFilter: meta.fields[key].filter,
          comparator: stringComparator
        };
        if (["description"].includes(key)) {
          defaultObj = {
            ...defaultObj,
            sortable: false,
            filter: type === formTypes?.ALL_LOG,
            floatingFilter: type === formTypes?.ALL_LOG
          };
        }
        defaultObj = overrideCellRender(key, defaultObj);
        if (["approverUserName"].includes(key)) {
          defaultObj = {
            ...defaultObj,
            sortable: false,
            filter: false,
            floatingFilter: false
          };
        }
        if (["samplesProcessed", "numberOfRuns", "subEquipment", "systemStatus"].includes(key)) {
          defaultObj = {
            ...defaultObj,
            maxWidth: 175
          };
        }
        colDef.push(defaultObj);
      }
    }
    defaultObj = {
      ...defaultObj,
      cellRenderer: "agGroupCellRenderer",
      width: 33,
      maxWidth: 33,
      pinned: "left",
      lockVisible: true,
      lockPinned: true
    };
    colDef.push({
      ...defaultObj,
      hide: false,
      field: "action",
      headerName: "",
      maxWidth: 35,
      pinned: "right",
      lockVisible: true,
      lockPinned: true,
      cellClass: "action-render",
      cellRenderer: actionRender
    });
    return colDef;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.fields]);

  const onGridReady = (params) => {
    setGridApi(params);
    if (userDetails?.lastDisplayColumns && typeof userDetails?.lastDisplayColumns === "string") {
      let lastDisplayColumns = JSON.parse(userDetails?.lastDisplayColumns);
      let logView = inventoryId ? logViews?.CENTRIC_VIEW : logViews?.NORMAL_VIEW;
      let columnState = lastDisplayColumns?.[type]?.[logView]?.displayDefinition;
      const updatedMapedState = columnState?.currentState?.map((item) => {
        item.hide = false;
        return item;
      });
      if (columnState) {
        params.columnApi.applyColumnState({
          state: updatedMapedState,
          applyOrder: true
        });
      }
      // reapplying search field after  date range filters were used
      params.api.gridOptionsService.set("quickFilterText", lastSearchedValue);
    }
  };

  const onColumnMoved = (params) => {
    var currentState = params.columnApi.getColumnState();
    let savecolumnObj = {
      displayName: "",
      displayDefinition: {
        currentState
      }
    };
    if (userDetails?.lastDisplayColumns && typeof userDetails?.lastDisplayColumns === "string") {
      var lastDisplayColumns = JSON.parse(userDetails?.lastDisplayColumns);
      var logView = inventoryId ? logViews?.CENTRIC_VIEW : logViews?.NORMAL_VIEW;
      var logColumns = { [type]: { [logView]: savecolumnObj } };
      lastDisplayColumns = { ...lastDisplayColumns, ...logColumns };

      const updateData = async (client) => {
        await client.mutate({
          mutation: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
          variables: {
            id: userDetails?.id,
            email: userDetails?.email,
            lastDisplayColumns: JSON.stringify(lastDisplayColumns)
          },
          fetchPolicy: "no-cache"
        });
      };
      updateData(client);
      loadUserInfo({
        ...userDetails,
        lastDisplayColumns: JSON.stringify(lastDisplayColumns)
      });
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      suppressMenu: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      sortable: true,
      minWidth: 210,
      filter: true,
      floatingFilter: true
    };
  }, []);
  const detailCellRenderer = useMemo(() => {
    return DetailCellRenderer;
  }, []);
  const scrollEvent = (event) => {
    const container = document.querySelector(".grid-container");
    if (Math.ceil(container.clientHeight + container.scrollTop) >= container.scrollHeight && nextToken) {
      paginationDispatch({ type: "nextData" });
    }
  };

  useEffect(() => {
    let attr = {
      height: "auto",
      columnDefs: dLabColumnDef,
      animateRows: true,
      rowExport: false,
      defaultToolPanel: "filters",
      suppressContextMenu: true,
      hiddenByDefault: true,
      rowData: logListData,
      suppressDragLeaveHidesColumns: true,
      onGridReady,
      onColumnMoved,
      rowStyle
    };

    setAttributes(() => attr);
    // eslint-disable-next-line
  }, [tempRowData?.current]);

  /**
   * function that applies filter from search columns
   * @param {*} params
   *
   */
  const changeFilters = (params) => {
    let rowData = [];
    gridRef?.current?.api?.forEachNodeAfterFilterAndSort((node) => rowData.push(node.data));

    if (gridRef?.current?.api && rowData.length === 0) {
      gridRef.current.api.showNoRowsOverlay();
    } else if (gridRef?.current?.api) {
      gridRef.current.api.hideOverlay();
    }
    onLastLogListData(rowData);
    const filters = params.api.getFilterModel();
    if (Object.keys(filters).length > 0) {
      dispatchAction({
        type: "setGridFilter",
        payload: true
      });
    } else {
      dispatchAction({
        type: "setGridFilter",
        payload: false
      });
    }
  };

  return (
    <div className="logListWrapper" data-testid={dataTestId}>
      {fetching && tempRowData?.current?.length === 0 ? (
        <div
          style={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress size={30} />
        </div>
      ) : (
        <>
          <div
            className="grid-container"
            onScroll={scrollEvent}
            style={{
              height: isInventoryId ? "300px" : containerHeight,
              overflowX: "auto"
            }}
          >
            <DLabGrid
              {...attributes}
              overlayNoRowsTemplate={
                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No equipment for the search criteria or Error occured </span>'
              }
              pagination={true}
              suppressPaginationPanel={false}
              defaultColDef={defaultColDef}
              masterDetail={true}
              domLayout="autoHeight"
              detailCellRenderer={detailCellRenderer}
              gridRef={gridRef}
              detailRowAutoHeight={detailRowAutoHeight}
              // Search Filter
              onFilterChanged={changeFilters}
            />
          </div>
        </>
      )}
      {showMenu && currentParams && <MenuList setShowMenu={setShowMenu} params={currentParams} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state?.user
});

export default compose(connect(mapStateToProps, { loadUserInfo }), withApollo)(LogBookTable);
