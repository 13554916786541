import * as types from "./actionTypes";
import initialState from "./initialState";
import { RunLogsFormAction } from "./actionTypes";

export default function RunLogsFormReducer(state = initialState, action: RunLogsFormAction) {
  switch (action.type) {
    case types.LOAD_TIPS:
      return { ...state, tips: [...action.payload] };
    case types.LOAD_MEDIA_TYPES:
      return { ...state, mediaTypes: [...action.payload] };
    case types.LOAD_GXP_READYS:
      return { ...state, gxpReadys: [...action.payload] };
    case types.LOAD_SYSTEM_STATUSS:
      return { ...state, systemStatuss: [...action.payload] };
    case types.LOAD_LOG_SHEET_ACTIONS:
      return { ...state, actionLog: [...action.payload] };
    case types.LOAD_INSTRUMENT_SUGGESTIONS:
      return {
        ...state,
        instrumentSuggestions: [...action.payload]
      };
    case types.LOAD_INSTRUMENT_SUGGESTION:
      return {
        ...state,
        instrumentSuggestion: action.payload ? { ...action.payload } : null
      };
    case types.LOAD_MEGA_CLUSTER_IDS:
      return {
        ...state,
        megaClusterIds: [...action.payload]
      };
    default:
      return state;
  }
}
