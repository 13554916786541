import { Formik } from "formik";
import { useContext, useState } from "react";

import LoadItemForm from "./LoadItemForm";
import { ItemDialogFormContext } from "./item-context/context";
import ClusterItemForm from "./ClusterItemForm";
import ClusterItemFormDialogBody from "./ClusterItemFormDialogBody";
import FormSteps from "./FormSteps";
import {
  CLUSTER_STEPS,
  CLUSTER_STEPS_ACTIONS,
  ClusterStepType,
  formStates,
  formTypes,
  FormTypesRestricted
} from "../../../constants";
import { useEffect } from "react";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { OwcWorkflowOverlay } from "@one/react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { LogBookSelectContext } from "../log-book-table/logBooksSelectAll/context";
import { cloneDeep } from "lodash";
import { ILogFormParams } from "../../../logs/StepperContainer";
import { useSearchParameters } from "@digitallab/grid-common-components";
import { useLocation } from "react-router-dom";
import { ObjectSchema } from "yup";
import { Assign, ObjectShape } from "yup/lib/object";
import { RootState } from "../../../reducers";
import { DigitalLabLogbookInstrumentModelType } from "../../../models/DigitalLabLogbookInstrumentModelType";

type ClusterItemFormDialogProps = {
  cleanItemFn: (params: {
    item: DigitalLabLogbookInstrumentModelType;
    isNew: boolean;
    user: unknown;
    formState: string;
  }) => DigitalLabLogbookInstrumentModelType;
  createItemChangeFn: () => void;
  validationSchemaFactory: () => ObjectSchema<Assign<ObjectShape, {}>>;
  emptyItem: Record<string, unknown>;

  type: FormTypesRestricted;
  equipmentDetail: DigitalLabLogbookInstrumentModelType;
};

const ClusterItemFormDialog = ({
  cleanItemFn,
  createItemChangeFn,
  validationSchemaFactory,
  emptyItem,

  type,
  equipmentDetail
}: ClusterItemFormDialogProps) => {
  const { search } = useLocation();
  const { description, action = "", runStatus = "", eLNid } = useSearchParameters(search) as unknown as ILogFormParams;
  const { inventoryId } = useParams<{ inventoryId?: string }>();
  const dataTestId = type === formTypes.RUN_LOG ? "run-log" : "action-log";
  const { activeStep, setActiveStep, handleClose } = useContext(ItemDialogFormContext);

  const { openWorkFlowModel, handleCloseWorkFlowModel, loadLogBookEquipment, formState, logType, loadFormState } =
    useContext(CoverSheetMainPageContext);

  const { clearSelection } = useContext(LogBookSelectContext);
  const [cleanItem, setCleanItem] = useState({});

  const [stepList, setStepList] = useState(type === formTypes.RUN_LOG ? CLUSTER_STEPS : CLUSTER_STEPS_ACTIONS);

  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    setCleanItem(() => {
      return cleanItemFn({
        item: cloneDeep(equipmentDetail),
        isNew: formState !== formStates.EDITABLE_FORM,
        user,
        formState
      });
    });

    // eslint-disable-next-line
  }, [logType, formState]);
  useEffect(() => {
    setStepList(type === formTypes.RUN_LOG ? CLUSTER_STEPS : CLUSTER_STEPS_ACTIONS);
  }, [type]);

  const handleStepClick = (step: ClusterStepType) => {
    if (formState === formStates.EDITABLE_FORM) {
      setActiveStep(step);
    }
  };

  const nextButtonHandle = () => {
    const newRowId = activeStep.id + 1;
    const activeRow = stepList.find((x) => x.id === newRowId);
    setActiveStep(activeRow!);
  };
  const prevButtonHandle = () => {
    const newRowId = activeStep.id - 1;
    const activeRow = stepList.find((x) => x.id === newRowId);
    setActiveStep(activeRow!);
  };

  const visibleChangeHandler = (event: { target: { [x: string]: string } }) => {
    if (event.target["data-testid"] !== "confirm-dialog") {
      const activeRow = stepList.find((x) => x.id === 0);
      setActiveStep(activeRow!);
      clearSelection();
      handleClose();
      if (!inventoryId) {
        loadLogBookEquipment(null);
      }
      loadFormState("");
      handleCloseWorkFlowModel();
    }
  };
  return (
    <LoadItemForm>
      <Formik
        validationSchema={validationSchemaFactory}
        initialValues={
          cleanItem
            ? { ...emptyItem, ...cleanItem, action, description, runStatus, eLNid }
            : { ...emptyItem, action, description, runStatus, eLNid }
        }
        enableReinitialize={true}
        onSubmit={async () => {}}
      >
        {openWorkFlowModel && (
          <>
            <OwcWorkflowOverlay
              className="owcoverlayZIndex"
              visible={openWorkFlowModel}
              onVisibleChange={visibleChangeHandler}
              disableBackdropClick
            >
              <ClusterItemFormDialogBody
                dataTestId={dataTestId}
                type={type}
                stepList={stepList}
                setStepList={setStepList}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                prevButtonHandle={prevButtonHandle}
                nextButtonHandle={nextButtonHandle}
                createItemChangeFn={createItemChangeFn}
                equipmentDetail={equipmentDetail}
              >
                <FormSteps
                  stepsArray={stepList}
                  activeStep={activeStep}
                  handleClick={handleStepClick}
                  isEditMode={formState === formStates.EDITABLE_FORM}
                  type={type}
                />
                <ClusterItemForm
                  type={type}
                  activeStep={activeStep}
                  dataTestId={dataTestId}
                  equipmentDetail={equipmentDetail}
                />
              </ClusterItemFormDialogBody>
            </OwcWorkflowOverlay>
          </>
        )}
      </Formik>
    </LoadItemForm>
  );
};

export default ClusterItemFormDialog;
