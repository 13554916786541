import { DigitalLabLogbookInstrumentModelType } from "../../../../models/DigitalLabLogbookInstrumentModelType";
import { ValueAttributeModelType } from "../../../../models/ValueAttributeModelType";

export const LOAD_TIPS = "[TIPS] LOAD_TIPS";
export const LOAD_MEDIA_TYPES = "[MEDIA_TYPES] LOAD_MEDIA_TYPES";
export const LOAD_GXP_READYS = "[GXP_READYS] LOAD_GXP_READYS";
export const LOAD_LOG_SHEET_ACTIONS = "[ACTION_LOG] LOAD_LOG_SHEET_ACTIONS";
export const LOAD_SYSTEM_STATUSS = "[SYSTEM_STATUSS] LOAD_SYSTEM_STATUSS";
export const LOAD_INSTRUMENT_SUGGESTIONS = "[INSTRUMENT_SUGGESTIONS] LOAD_INSTRUMENT_SUGGESTIONS";
export const LOAD_INSTRUMENT_SUGGESTION = "[INSTRUMENT_SUGGESTION] LOAD_INSTRUMENT_SUGGESTION";
export const LOAD_MEGA_CLUSTER_IDS = "[MEGA_CLUSTER_IDS] LOAD_MEGA_CLUSTER_IDS";

export type RunLogsFormAction =
  | { type: typeof LOAD_TIPS; payload: ValueAttributeModelType[] }
  | { type: typeof LOAD_MEDIA_TYPES; payload: ValueAttributeModelType[] }
  | { type: typeof LOAD_GXP_READYS; payload: ValueAttributeModelType[] }
  | { type: typeof LOAD_SYSTEM_STATUSS; payload: ValueAttributeModelType[] }
  | { type: typeof LOAD_LOG_SHEET_ACTIONS; payload: ValueAttributeModelType[] }
  | { type: typeof LOAD_INSTRUMENT_SUGGESTIONS; payload: DigitalLabLogbookInstrumentModelType[] }
  | { type: typeof LOAD_INSTRUMENT_SUGGESTION; payload: DigitalLabLogbookInstrumentModelType | null }
  | { type: typeof LOAD_MEGA_CLUSTER_IDS; payload: string[] };
