import { useContext, useEffect, useState } from "react";
import { CoverSheetMainPageContext } from "../features/cover-sheet/cover-sheet-main-page-context/context";
import { entryType, formStates, formTypes } from "../constants";
import ClusterActionDialog from "../features/equipment-list/ClusterActionDialog";
import ItemWrapper from "../features/log-book/log-book-item-form-dialog/item-context/ItemWrapper";
import { CenteredSpinner, useSearchParameters } from "@digitallab/grid-common-components";
import { useLocation } from "react-router-dom";
import { ILogFormParams } from "./StepperContainer";
import { withApollo, WithApolloClient } from "react-apollo";
import { getEquipDetailInfo } from "../features/log-book/LoadLogBooksInfo";
import { LIST_DIGITAL_LAB_LOGBOOK_SUBEQUIPMENT_INSTRUMENT } from "../gql/logBooksapi";
import { getAllData } from "../utils/helpers/fetching";
import LogbookSelectedWrapper from "../features/log-book/log-book-table/logBooksSelectAll/LogBookSelectedWrapper";
import ItemCoverSheetWrapper from "../features/log-book/log-book-item-cover-sheet-dialog/cover-sheet-context/ItemCoverSheetWrapper";
import { InstrumentWithSubEquipmentDetailsModelType } from "../models/DigitalLabLogbookInstrumentModelType";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { RootState } from "../reducers";
export const AddClusterContainer = withApollo(({ client }: { client: WithApolloClient<{}> }) => {
  const { pathname, search } = useLocation();
  const [equipmentDetail, setEquipmentDetail] = useState<undefined | InstrumentWithSubEquipmentDetailsModelType>();
  const { equipmentIdList } = useSearchParameters(search) as unknown as ILogFormParams;
  const isRunLog = pathname.includes("run");

  const storeUser = useSelector((store: { user: { site: string } }) => store.user);
  const systemStatus = useSelector((state: RootState) => state.runLogsForm.systemStatuss);
  const gxpReadys = useSelector((state: RootState) => state.runLogsForm.gxpReadys);

  const [, setClusterLogStatus] = useState(false);

  useEffect(() => {
    const getEquipments = async () => {
      const getSubEquipmentsDetailInfo = async (id: string) => {
        try {
          const result = await getAllData({
            client,
            query: LIST_DIGITAL_LAB_LOGBOOK_SUBEQUIPMENT_INSTRUMENT,
            variables: {
              limit: 1000,
              filter: {
                clusterId: { eq: id },
                siteName: { eq: storeUser.site }
              }
            },
            dataPath: ["data", "listDigitalLabLogbookInstruments"],
            drillData: true
          });
          return result?.items;
        } catch (err) {
          console.error(err);
        }
      };
      const [foundEquipment, subEquipment] = await Promise.all([
        getEquipDetailInfo(equipmentIdList[0], client),
        getSubEquipmentsDetailInfo(equipmentIdList[0])
      ]);

      if (foundEquipment?.selectedLogType === formTypes.ACTION_LOG) {
        foundEquipment.gxpReady =
          find(gxpReadys, {
            key: foundEquipment.gxpReady?.key
          }) || null;
        foundEquipment.systemStatus =
          find(systemStatus, {
            value: foundEquipment.systemStatus?.value
          }) || null;

        if (foundEquipment.entryType === entryType.cluster) {
          foundEquipment.gxpReadyCluster =
            find(gxpReadys, {
              key: foundEquipment.gxpReadyCluster?.key
            }) || null;
          foundEquipment.systemStatusCluster =
            find(systemStatus, {
              value: foundEquipment.systemStatusCluster?.value
            }) || null;
        }
      }

      subEquipment.forEach(
        (equipment: {
          gxpReady: string | null | {};
          qualificationStatus: string;
          systemStatus: string | null | {};
        }) => {
          equipment.gxpReady =
            find(gxpReadys, {
              key: equipment.qualificationStatus
            }) || null;
          equipment.systemStatus =
            find(systemStatus, {
              value: equipment.systemStatus
            }) || null;
        }
      );
      foundEquipment.subEquipment = subEquipment;
      setEquipmentDetail(foundEquipment);
    };
    getEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentIdList]);

  const { handleOpenWorkFlowModel, loadFormType } = useContext(CoverSheetMainPageContext);
  handleOpenWorkFlowModel({ isCluster: true, form: formStates?.PARTIAL_NEW_FORM });
  loadFormType(isRunLog ? formTypes?.RUN_LOG : formTypes?.ACTION_LOG);

  return equipmentDetail ? (
    <ItemCoverSheetWrapper>
      <LogbookSelectedWrapper equipmentDetail={equipmentDetail} selectedInstruments={[]}>
        <ItemWrapper entryDataType={equipmentDetail?.entryType} setClusterLogStatus={setClusterLogStatus}>
          <ClusterActionDialog
            type={isRunLog ? formTypes?.RUN_LOG : formTypes?.ACTION_LOG}
            equipmentDetail={equipmentDetail}
          />
        </ItemWrapper>
      </LogbookSelectedWrapper>
    </ItemCoverSheetWrapper>
  ) : (
    <CenteredSpinner />
  );
});
