import React, { ReactNode, useState } from "react";
import { CoverSheetMainPageContext } from "./context";
import { APP_PATHS, formStates, formTypes, LOG_TYPES } from "../../../constants";
import {
  DigitalLabLogbookInstrumentModelType,
  InstrumentWithSubEquipmentDetailsModelType
} from "../../../models/DigitalLabLogbookInstrumentModelType";
import { useHistory } from "react-router-dom";

const CoverSheetMainPageWrapper = ({ children }: { children: ReactNode }) => {
  const [refreshCoverSheet, setRefreshCoverSheet] = useState(false);
  const [handleOpenChange, setHandleOpenChange] = useState(false);
  const [tabValue, setTabValue] = useState(LOG_TYPES.ADD_RUN_LOG);
  const [openWorkFlowModel, setOpenWorkFlowModel] = useState(false);
  const [logBookEquipment, setLogBookEquipment] = useState<DigitalLabLogbookInstrumentModelType | null>(null);
  const [isClusterForm, setIsClusterForm] = useState(false);
  const [formState, setFormState] = useState(formStates?.NEW_FORM);
  const [logType, setLogType] = useState(formTypes?.RUN_LOG);
  const [refreshAfterEditOrNew, setRefreshAfterEditOrNew] = useState(false);
  const [allLogType, setAllLogType] = useState(formTypes?.RUN_LOG);
  const [allLogHierarchyData, setAllLogHierarchyData] = useState<InstrumentWithSubEquipmentDetailsModelType[] | null>(
    null
  );

  const history = useHistory();

  const handleOpenWorkFlowModel = ({
    isCluster = false,
    form = formStates?.NEW_FORM
  }: {
    isCluster?: boolean | undefined;
    form?: string | undefined;
  }) => {
    setFormState(() => form);
    setIsClusterForm(isCluster);
    setOpenWorkFlowModel(true);
  };

  const handleCloseWorkFlowModel = () => {
    setIsClusterForm(false);
    setOpenWorkFlowModel(false);
    history.push(APP_PATHS.ROOT);
  };

  const loadLogBookEquipment = (logBookEquip: DigitalLabLogbookInstrumentModelType | null) => {
    setLogBookEquipment(() => logBookEquip);
  };

  const loadFormType = (type: string) => {
    setLogType(() => type);
  };

  const loadAllFormType = (type: string) => {
    setAllLogType(() => type);
  };

  const loadAllLogHierarchyData = (type: InstrumentWithSubEquipmentDetailsModelType[] | null) => {
    setAllLogHierarchyData(() => type);
  };

  const loadFormState = (formState: string) => {
    setFormState(() => formState);
  };

  const refreshTable = () => {
    setRefreshAfterEditOrNew((prevState) => !prevState);
  };

  return (
    <CoverSheetMainPageContext.Provider
      value={{
        refreshCoverSheet,
        setRefreshCoverSheet,
        handleOpenChange,
        setHandleOpenChange,
        tabValue,
        setTabValue,
        openWorkFlowModel,
        setOpenWorkFlowModel,
        handleOpenWorkFlowModel,
        handleCloseWorkFlowModel,
        loadLogBookEquipment,
        logBookEquipment,
        isClusterForm,
        formState,
        logType,
        loadFormType,
        loadFormState,
        refreshTable,
        refreshAfterEditOrNew,
        allLogType,
        loadAllFormType,
        allLogHierarchyData,
        loadAllLogHierarchyData
      }}
    >
      {children}
    </CoverSheetMainPageContext.Provider>
  );
};

export default CoverSheetMainPageWrapper;
