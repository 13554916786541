import { generateID } from "@digitallab/grid-common-components";
import { OwcTextarea } from "@one/react";
import { Module } from "../../../../constants";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import { FormikProps } from "formik";

export const DescriptionTestArea = ({ formik }: { formik: FormikProps<{ [name: string]: string }> }) => (
  <OwcTextarea
    data-testid="action-data-inputs-description-input"
    id={generateID.textBoxID(Module.POPUP_FIELDS.actionDescription, "textbox")}
    label={COMMON_LOGS_DATA_MODEL.description.value + ` *`}
    onInputChange={(event: { target: { value: string } }) => {
      formik.setFieldValue("description", event.target.value, true);
    }}
    onInputKeyDown={(event: { target: { value: string } }) => {
      formik.setFieldValue("description", event.target.value, true);
    }}
    onInputReset={() => formik.setFieldValue("description", "", true)}
    value={formik.values.description}
    required
    resizable
    reset
    rows={4}
    cols={20}
    maxLength={200}
    minLength={0}
  />
);
