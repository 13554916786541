import { AWSAppSyncClient } from "aws-appsync";
import { AUTH_TYPE } from "aws-appsync-auth-link";
import { Auth } from "aws-amplify";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { BOOKING_ENDPOINT } from "./constants";

let clientInstance: AWSAppSyncClient<NormalizedCacheObject> | null = null;

export const getAppSyncBookingClient = () => {
  if (!clientInstance) {
    clientInstance = new AWSAppSyncClient({
      url: BOOKING_ENDPOINT,
      region: process.env.REACT_APP_AWS_PROJECT_REGION!,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken()
      },
      disableOffline: true
    });
  }
  return clientInstance;
};
