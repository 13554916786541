import { FormikProps } from "formik";
import { StatusSelect } from "./StatusSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
export const QualificationStatusSelect = ({
  formik,
  editMode,
  isPropagationDisabled,
  numberOfSelectedEquipment
}: {
  formik: FormikProps<{ [name: string]: { [key: string]: string } }>;
  editMode: boolean;
  isPropagationDisabled: boolean;
  numberOfSelectedEquipment: number;
}) => {
  const gxpReadys = useSelector((store: RootState) => store.runLogsForm.gxpReadys);

  return (
    <StatusSelect
      formik={formik}
      editMode={editMode}
      isPropagationDisabled={isPropagationDisabled}
      numberOfSelectedEquipment={numberOfSelectedEquipment}
      property="gxpReady"
      options={gxpReadys}
    />
  );
};
