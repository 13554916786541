import { Form, useFormikContext } from "formik";
import { useContext, useState, useEffect } from "react";
import { Notify } from "@digitallab/grid-common-components";

import styled from "styled-components";
import { ItemDialogFormContext } from "./item-context/context";
import { VALIDATE_ELN_ID } from "../../../gql/logBooksapi";
import { withApollo } from "react-apollo";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { generateID } from "@digitallab/grid-common-components";
import { Module, actionLogManditoryFields, formStates, formTypes, tableReloadTime } from "../../../constants";
import { LogBookSelectContext } from "../log-book-table/logBooksSelectAll/context";
import { ConfirmDialog } from "../../../components/shared/ConfirmDialog";
import useDialog from "../../../utils/hooks/useDialog";
import ReasonDropdown from "../ReasonDropdown";
import { CircularProgress, Divider } from "@mui/material";
import { OwcButton } from "@one/react";
import { ActionContainer, ContentContainer } from "./ItemFormDialogBodyStyle";
import { PreviousButtonWrapper } from "./FormsStyled";
import { CoverSheetMainPageContext } from "../../cover-sheet/cover-sheet-main-page-context/context";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { isEqual, omit } from "lodash";
import omitDeep from "omit-deep-lodash";
import moment from "moment";
import { intersection } from "lodash";
import ActionLogLink from "../ActionLogLink";
import SyncActionContent from "./SyncActionContent";

const ActionButtonBox = styled.div`
  display: flex;
`;

export const FullScreenCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-tap-highlight-color: transparent;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const ClusterItemFormDialogBody = ({
  children,
  type,
  dataTestId,
  client = {},
  stepList,
  setStepList,
  activeStep,
  setActiveStep,
  nextButtonHandle,
  prevButtonHandle,
  createItemChangeFn,
  equipmentDetail
}) => {
  const { inventoryId } = useParams();
  const { resetForm, submitForm, isSubmitting, errors, setErrors, values, setFieldValue, setValues } =
    useFormikContext();
  const {
    handleClose,
    subClusterFlag,
    isSubmitDisabled,
    setIsSubmitDisabled,
    actionLogLinkName,
    setActionLogLinkName,
    actionLogLinkUrl,
    setActionLogLinkUrl,
    actionLogLinkCurrentIndex,
    setActionLogLinkCurrentIndex,
    actionLogLinkCurrentFlag,
    setActionLogLinkCurrentFlag,
    addLinkFlag,
    setAddLinkFlag,
    propagationSync
  } = useContext(ItemDialogFormContext);

  const NextButton = ({ disabled }) => (
    <OwcButton
      type="submit"
      data-testid="cluster-next-step-button"
      disabled={disabled}
      variant="primary"
      onClick={() => {
        nextButtonHandle(formik.values);
      }}
    >
      Next
    </OwcButton>
  );

  const handleAddLogClick = async () => {
    handlerOnAddNew(false);
    if (!inventoryId) {
      loadLogBookEquipment(null);
    }
    loadFormState(null);
    handleCloseWorkFlowModel();
  };

  const AddNewLogButton = ({ onClick }) => (
    <OwcButton
      disabled={isSubmitting || Object.keys(errors)?.length > 0}
      variant="primary"
      onClick={onClick}
      data-testid={`${dataTestId}-creating-and-editing-dialog-actions-add-${type}-button`}
      id={
        dataTestId === "run-log"
          ? generateID.buttonID(Module.BUTTON.run_log_popup.add_new_run, "button")
          : generateID.buttonID(Module.BUTTON.action_log_popup.add_additional_log, "button")
      }
    >
      {type === formTypes?.RUN_LOG ? LOGBOOK_LABEL.BUTTON.add_new_run : LOGBOOK_LABEL.BUTTON.add_new_log}
    </OwcButton>
  );

  const addLink = () => {
    let currentExternalDocment = values?.externalDocument ? values?.externalDocument : [];
    currentExternalDocment = [...currentExternalDocment, { link: actionLogLinkUrl, name: actionLogLinkName }];
    setFieldValue("externalDocument", currentExternalDocment, true);
    setActionLogLinkName(null);
    setActionLogLinkUrl(null);
    setActionLogLinkCurrentIndex(null);
    setAddLinkFlag(false);
    Notify({
      type: "success",
      icon: "circle_confirm",
      title: "",
      text: `Link added successfully`
    });
  };

  const editLink = () => {
    let currentExternalDocment = [...values?.externalDocument];
    currentExternalDocment[actionLogLinkCurrentIndex] = {
      link: actionLogLinkUrl,
      name: actionLogLinkName
    };
    setFieldValue("externalDocument", currentExternalDocment, true);
    setActionLogLinkName(null);
    setActionLogLinkUrl(null);
    setAddLinkFlag(false);
    Notify({
      type: "success",
      icon: "circle_confirm",
      title: "",
      text: `Link saved successfully`
    });
  };

  const deleteLink = () => {
    const currentExternalDocment = values?.externalDocument?.filter((item, index) => {
      return index !== actionLogLinkCurrentIndex;
    });
    setFieldValue("externalDocument", currentExternalDocment, true);
    setActionLogLinkName(null);
    setActionLogLinkUrl(null);
    setActionLogLinkCurrentIndex(null);
    setAddLinkFlag(false);
    Notify({
      type: "success",
      icon: "circle_confirm",
      title: "",
      text: `Link deleted successfully`
    });
  };

  const handleActionLinkChange = () => {
    if (actionLogLinkCurrentFlag === "Add") {
      addLink();
    }
    if (actionLogLinkCurrentFlag === "Edit") {
      editLink();
    }
    if (actionLogLinkCurrentFlag === "Delete") {
      deleteLink();
    }
  };
  useEffect(() => {
    if (actionLogLinkCurrentFlag) {
      openDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionLogLinkCurrentFlag]);
  const { selected, clearSelection, selectedOnlyParents } = useContext(LogBookSelectContext);
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useMatomo();
  const formik = useFormikContext();
  const { openDialog, ...dialogProps } = useDialog();
  const [selectedReason, setSelectedReason] = useState("");
  const [addComment, setAddComment] = useState("");
  const {
    handleCloseWorkFlowModel,
    loadLogBookEquipment,
    formState,
    setRefreshCoverSheet,
    loadFormState,
    refreshTable,
    allLogType
  } = useContext(CoverSheetMainPageContext);
  const { dispatchAction } = useContext(PaginationContext);

  const confirmDialogProps = {
    ...dialogProps,
    approveColor: "primary",
    approveVariant: "contained",
    cancelText: "Cancel",
    cancelVariant: "outlined",
    cancelColor: "primary",
    approveText: "OK",
    onApprove: async () => {
      handlerOnEdit(true);
      if (!inventoryId) {
        loadLogBookEquipment(null);
      }
      loadFormState(null);
      handleCloseWorkFlowModel();
    },
    onCancel: () => {
      setSelectedReason("");
      setAddComment("");
    },
    title: "Reason for editing log entry",
    isEnabled: selectedReason,
    content: (
      <ReasonDropdown
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
        addComment={addComment}
        setAddComment={setAddComment}
      />
    ),
    disableBackdropClick: true
  };

  const updateReduxKeys = (updatedDetail) => {
    let tempDetail;
    updatedDetail = omitDeep(
      updatedDetail,
      "__typename",
      "id",
      "approverId",
      "runLogEntryId",
      "logSheetEntryId",
      "approverUserName",
      "instrumentDetails"
    );
    tempDetail = {
      ...updatedDetail,
      numberOfRuns: `${updatedDetail?.numberOfRuns}`,
      materialNumber: updatedDetail?.materialNumber || null
    };
    return tempDetail;
  };

  const updateFormikKeys = (updatedDetail) => {
    let tempDetail;
    updatedDetail = omitDeep(
      updatedDetail,
      "__typename",
      "editReason",
      "editComment",
      "runLogEntryId",
      "logSheetEntryId",
      "id",
      "approverId",
      "approverUserName",
      "instrumentDetails"
    );
    tempDetail = {
      ...updatedDetail,
      runStartDate: (updatedDetail.runStartDate =
        moment(updatedDetail?.runStartDate).format("YYYY-MM-DD") || updatedDetail.runStartDate),
      runEndDate: (updatedDetail.runEndDate =
        moment(updatedDetail?.runEndDate).format("YYYY-MM-DD") || updatedDetail.runEndDate),
      runIdentification: updatedDetail?.runIdentification || null,
      samplesProcessed: updatedDetail?.samplesProcessed || null,
      numberOfRuns: `${updatedDetail?.numberOfRuns}` || null,
      description: updatedDetail?.description || null,
      defectId: updatedDetail?.defectId || null,
      eLNid: updatedDetail?.eLNid || null,
      tipsUsed: updatedDetail?.tipsUsed || null,
      operatorId: updatedDetail?.operatorId || "",
      materialNumber: updatedDetail?.materialNumber || null,
      gxpReady: omit(updatedDetail?.gxpReady, "site"),
      gxpReadyCluster: omit(updatedDetail?.gxpReadyCluster, "site"),
      systemStatus: omit(updatedDetail?.systemStatus, "site"),
      systemStatusCluster: omit(updatedDetail?.systemStatusCluster, "site")
    };
    return tempDetail;
  };

  useEffect(() => {
    if (formState === formStates?.EDITABLE_FORM && (type === formTypes?.RUN_LOG || allLogType === formTypes?.RUN_LOG)) {
      let tempequipmentDetail = updateReduxKeys(equipmentDetail);
      let tempFormikValues = updateFormikKeys(values);
      setIsSubmitDisabled(() => isEqual(tempequipmentDetail, tempFormikValues));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    for (let index = 0; index < stepList?.length; index++) {
      stepList[index].valid = stepList[index].id < activeStep.id;
      stepList[index].completed = stepList[index].id < activeStep.id;
      stepList[index].active = stepList[index].id === activeStep.id;
    }
    setStepList([...stepList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (formState === formStates?.EDITABLE_FORM) {
      for (let index = 0; index < stepList?.length; index++) {
        stepList[index].valid = true;
        stepList[index].completed = true;
        stepList[index].active = stepList[index].id === activeStep.id;
      }
    }
    setStepList([...stepList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eLNidValidationCheck = async (eLNid) => {
    try {
      const result = await client.query({
        query: VALIDATE_ELN_ID,
        fetchPolicy: "no-cache",
        variables: {
          eLNid
        }
      });
      return result?.data?.getElnIdValidation;
    } catch (err) {
      console.warn(err);
      Notify({
        type: "warning",
        icon: "alarm",
        title: "",
        text: "Unable to verify eLN ID (either eLN or the interface is down)",
        closeButton: true
      });
    } finally {
    }
  };

  const handlerOnAddNew = async () => {
    // Track click on button
    if (type === formTypes?.ACTION_LOG) {
      trackEvent({
        category: `Action Cluster ${type} added`,
        action: "click-event"
      });
      handleSubmit(false);
    } else {
      trackEvent({ category: `${type} log added`, action: "click-event" });
      handleSubmit(false);
    }
  };

  const handlerOnEdit = async () => {
    // Track click on button
    if (type === formTypes?.ACTION_LOG) {
      trackEvent({
        category: `Action ${type} Cluster Edited `,
        action: "click-event"
      });
      handleSubmit(true);
    } else {
      trackEvent({ category: `Run Log Edited `, action: "click-event" });
      handleSubmit(true);
    }
  };

  const handleSubmit = async (closePopup = false) => {
    const activeRow = stepList.find((x) => x.id === 0);
    setActiveStep(activeRow);
    if (type === formTypes?.RUN_LOG && values?.eLNid) {
      setLoading(true);
      const eLNidValidation = await eLNidValidationCheck(values?.eLNid);
      setLoading(false);
      if (eLNidValidation?.statusCode === 400) {
        setErrors({
          eLNid: " "
        });
        Notify({
          type: "warning",
          icon: "alarm",
          title: "",
          text: "Unable to verify eLN ID (either eLN or the interface is down)",
          closeButton: true
        });
      } else if (eLNidValidation?.statusCode === 403) {
        setErrors({
          eLNid: " "
        });
        Notify({
          type: "warning",
          icon: "alarm",
          title: "",
          text: "Invalid eLN experiment ID",
          closeButton: true
        });
      } else if (eLNidValidation?.statusCode === 200) {
        setValues({
          ...values,
          editReason: selectedReason,
          editComment: addComment,
          eLNGUID: eLNidValidation?.elnAttributes[0]?.guid
        });
        const response = await createItemChangeFn(
          {
            ...values,
            editReason: selectedReason,
            editComment: addComment,
            eLNGUID: eLNidValidation?.elnAttributes[0]?.guid
          },
          {
            client,
            isNew: formState !== formStates?.EDITABLE_FORM,
            selectedEquipments: selected,
            equipmentDetail,
            selectedOnlyParents
          }
        );
        setTimeout(() => {
          if (inventoryId && response?.data) {
            setRefreshCoverSheet((prevState) => !prevState);
          }
          clearSelection();
          dispatchAction({
            type: "clearAll"
          });
          refreshTable();
          resetForm();
        }, tableReloadTime);
        await submitForm();
        if (closePopup) {
          handleClose();
        } else {
          setValues({
            ...values,
            serialNumber: "",
            equipmentId: ""
          });
        }
      }
    } else {
      setValues({
        ...values,
        editReason: selectedReason,
        editComment: addComment
      });
      const response = await createItemChangeFn(
        {
          ...values,
          editReason: selectedReason,
          editComment: addComment
        },
        {
          client,
          isNew: formState !== formStates?.EDITABLE_FORM,
          selectedEquipments: selected,
          equipmentDetail,
          selectedOnlyParents
        }
      );

      setTimeout(() => {
        if (inventoryId && response?.data) {
          setRefreshCoverSheet((prevState) => !prevState);
        }
        clearSelection();
        dispatchAction({
          type: "clearAll"
        });
        refreshTable();
        resetForm();
      }, tableReloadTime);
      await submitForm();
      if (closePopup) {
        handleClose();
      } else {
        setValues({
          ...values
        });
      }
    }
  };
  const updateReasons = () => {
    openDialog();
  };
  const openSyncDialog = () => {
    openDialog();
  };
  return (
    <>
      {loading && (
        <FullScreenCentered>
          <CircularProgress data-testid="app-loading" size={80} />
        </FullScreenCentered>
      )}

      <div slot="title">
        {formState !== formStates?.EDITABLE_FORM
          ? `Add ${type === formTypes?.RUN_LOG ? "Run" : "Action"} log`
          : `Edit ${type === formTypes?.RUN_LOG ? "Run" : "Action"} log`}
      </div>
      <Divider />
      <ContentContainer slot="content">
        <Form style={{ width: "98%" }}>{children}</Form>
      </ContentContainer>
      <ActionContainer slot="actions">
        {type === formTypes?.RUN_LOG ? (
          <>
            <PreviousButtonWrapper>
              {activeStep.id === 1 && (
                <OwcButton
                  style={{ textTransform: "none" }}
                  type="submit"
                  data-testid="cluster-previous-step-button"
                  disabled={activeStep.id === 0}
                  onClick={() => {
                    //TODO commented this code for future referrence
                    // if (formik.isValid && Object.keys(formik.errors).length === 0) {
                    prevButtonHandle(formik.values);
                    // }
                  }}
                  variant="secondary"
                >
                  Previous
                </OwcButton>
              )}
            </PreviousButtonWrapper>
            <OwcButton
              data-testid={`${dataTestId}-creating-and-editing-dialog-actions-cancel-button`}
              disabled={isSubmitting}
              style={{ marginRight: "10px" }}
              onClick={() => {
                const activeRow = stepList.find((x) => x.id === 0);
                setActiveStep(activeRow);
                clearSelection();
                handleClose();
                if (!inventoryId) {
                  loadLogBookEquipment(null);
                }
                loadFormState(null);
                handleCloseWorkFlowModel();
                setTimeout(() => {
                  if (inventoryId) {
                    setRefreshCoverSheet((prevState) => !prevState);
                  }
                  dispatchAction({
                    type: "clearAll"
                  });
                  refreshTable();
                  resetForm();
                });
              }}
              id={
                dataTestId === "run-log"
                  ? generateID.buttonID(Module.BUTTON.run_log_popup.cancel, "button")
                  : generateID.buttonID(Module.BUTTON.action_log_popup.cancel, "button")
              }
              variant="secondary"
            >
              {LOGBOOK_LABEL.BUTTON.cancel}
            </OwcButton>
            <ActionButtonBox>
              {activeStep.id === 0 ? (
                <NextButton disabled={selected?.length <= 0} />
              ) : formState !== formStates?.EDITABLE_FORM ? (
                <AddNewLogButton onClick={handleAddLogClick} />
              ) : (
                <>
                  <OwcButton
                    disabled={Object.keys(errors)?.length > 0 || isSubmitDisabled}
                    onClick={() => {
                      updateReasons();
                    }}
                    variant="primary"
                    data-testid={`${dataTestId}-creating-and-editing-dialog-actions-edit-button`}
                    id={
                      dataTestId === "run-log"
                        ? generateID.buttonID(Module.BUTTON.run_log_popup.edit_run, "button")
                        : generateID.buttonID(Module.BUTTON.action_log_popup.edit_log, "button")
                    }
                  >
                    Save
                  </OwcButton>
                  {!actionLogLinkCurrentFlag && <ConfirmDialog {...confirmDialogProps} />}
                </>
              )}
            </ActionButtonBox>
          </>
        ) : (
          <>
            <PreviousButtonWrapper>
              {activeStep.id !== 0 && (
                <OwcButton
                  style={{ textTransform: "none" }}
                  type="submit"
                  data-testid="cluster-previous-step-button"
                  disabled={activeStep.id === 0}
                  onClick={() => {
                    //TODO commented this code for future referrence
                    // if (formik.isValid && Object.keys(formik.errors).length === 0) {
                    prevButtonHandle(formik.values);
                    // }
                  }}
                  variant="secondary"
                >
                  Previous
                </OwcButton>
              )}
            </PreviousButtonWrapper>
            <OwcButton
              data-testid={`${dataTestId}-creating-and-editing-dialog-actions-cancel-button`}
              disabled={isSubmitting}
              style={{ marginRight: "10px" }}
              onClick={() => {
                const activeRow = stepList.find((x) => x.id === 0);
                setActiveStep(activeRow);
                clearSelection();
                handleClose();
                if (!inventoryId) {
                  loadLogBookEquipment(null);
                }
                loadFormState(null);
                handleCloseWorkFlowModel();
                setTimeout(() => {
                  if (inventoryId) {
                    setRefreshCoverSheet((prevState) => !prevState);
                  }
                  dispatchAction({
                    type: "clearAll"
                  });
                  refreshTable();
                  resetForm();
                });
              }}
              id={
                dataTestId === "run-log"
                  ? generateID.buttonID(Module.BUTTON.run_log_popup.cancel, "button")
                  : generateID.buttonID(Module.BUTTON.action_log_popup.cancel, "button")
              }
              variant="secondary"
            >
              {LOGBOOK_LABEL.BUTTON.cancel}
            </OwcButton>

            <ActionButtonBox>
              {activeStep.id === 0 || activeStep.id === 1 ? (
                activeStep.id === 0 ? (
                  <NextButton disabled={selected?.length <= 0} />
                ) : (
                  activeStep.id === 1 && (
                    <NextButton
                      disabled={
                        intersection(actionLogManditoryFields, Object.keys(errors))?.length > 0 || selected?.length <= 0
                      }
                    />
                  )
                )
              ) : formState !== formStates?.EDITABLE_FORM ? (
                <>
                  <OwcButton
                    disabled={isSubmitting || Object.keys(errors)?.length > 0 || subClusterFlag}
                    variant="primary"
                    onClick={async () => {
                      if (!propagationSync) {
                        handlerOnAddNew(false);
                        if (!inventoryId) {
                          loadLogBookEquipment(null);
                        }
                        loadFormState(null);
                        handleCloseWorkFlowModel();
                      } else {
                        openSyncDialog();
                      }
                    }}
                    data-testid={`${dataTestId}-creating-and-editing-dialog-actions-add-${type}-button`}
                    id={
                      dataTestId === "run-log"
                        ? generateID.buttonID(Module.BUTTON.run_log_popup.add_new_run, "button")
                        : generateID.buttonID(Module.BUTTON.action_log_popup.add_additional_log, "button")
                    }
                  >
                    {`Add ${type === formTypes?.RUN_LOG ? "Run" : "Action"} log`}
                  </OwcButton>
                  <ConfirmDialog
                    {...confirmDialogProps}
                    approveText="Proceed"
                    onApprove={async () => {
                      handlerOnAddNew(false);
                      if (!inventoryId) {
                        loadLogBookEquipment(null);
                      }
                      loadFormState(null);
                      handleCloseWorkFlowModel();
                    }}
                    title="Propagate to repository"
                    isEnabled={true}
                    content={<SyncActionContent Dipsflag={propagationSync} />}
                  />
                </>
              ) : (
                <>
                  <OwcButton
                    disabled={Object.keys(errors)?.length > 0 || isSubmitDisabled || subClusterFlag}
                    onClick={() => {
                      updateReasons();
                    }}
                    variant="primary"
                    data-testid={`${dataTestId}-creating-and-editing-dialog-actions-edit-button`}
                    id={
                      dataTestId === "run-log"
                        ? generateID.buttonID(Module.BUTTON.run_log_popup.edit_run, "button")
                        : generateID.buttonID(Module.BUTTON.action_log_popup.edit_log, "button")
                    }
                  >
                    Save
                  </OwcButton>
                  {!actionLogLinkCurrentFlag && <ConfirmDialog {...confirmDialogProps} />}
                </>
              )}
            </ActionButtonBox>
          </>
        )}
        {actionLogLinkCurrentFlag && (
          <ConfirmDialog
            {...dialogProps}
            approveText={actionLogLinkCurrentFlag === "Edit" ? "Save" : actionLogLinkCurrentFlag}
            approveColor="primary"
            approveVariant="contained"
            cancelText="Cancel"
            cancelVariant="outlined"
            cancelColor="primary"
            onApprove={handleActionLinkChange}
            onCancel={() => {
              setActionLogLinkName(null);
              setActionLogLinkUrl(null);
              setActionLogLinkCurrentFlag(null);
              setActionLogLinkCurrentIndex(null);
            }}
            title={`${actionLogLinkCurrentFlag} link`}
            isEnabled={addLinkFlag}
            content={
              <ActionLogLink
                actionLogLinkName={actionLogLinkName}
                setActionLogLinkName={setActionLogLinkName}
                actionLogLinkUrl={actionLogLinkUrl}
                setActionLogLinkUrl={setActionLogLinkUrl}
                actionLogLinkCurrentFlag={actionLogLinkCurrentFlag}
                setAddLinkFlag={setAddLinkFlag}
              />
            }
            disableBackdropClick={true}
          />
        )}
      </ActionContainer>
    </>
  );
};

export default withApollo(ClusterItemFormDialogBody);
